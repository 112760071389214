import React from 'react';
import PopupBase from "../Popups/PopupBase";
import {useTranslation} from "../../services/i18n/i18nService";
import {usePopup} from "../../services/PopupService";

function PopupSelectorCalendar() {
    const { t: _ } = useTranslation();
    const { removePopup } = usePopup();

    return (
        <PopupBase
            title=""
            footerButtons={
                [
                    {
                        label: _("Save and Apply"),
                        background: "bg-black",
                        color: "text-white",
                        className: "w-12",
                        onClick: removePopup,
                    },
                ]
            }>
        </PopupBase>
    );
}

export default PopupSelectorCalendar;