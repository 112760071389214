import React, { useEffect, useState } from 'react';
import { Icons } from "../../assets/Svgs";
import InputCheckbox from "./InputCheckbox";

const defaultSortComp = (a, b) => {
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
}

export const SelectionType = {
    NONE: "none",
    SINGLE: "single",
    MULTIPLE: "multiple",
}

export const SortDirection = {
    ASC: "asc",
    DESC: "desc",
}

const Table = ({
    displayHeaders = true,
    selectionType = SelectionType.NONE,
    bgColorClass,
    trColorClass,
    roundedClass,
    tdheightClass,
    tdwidthClass,
    tdpaddingClass,
    columns,
    rows,
    onSelectionChange,
    filterLambda = null,
}) => {

    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [selectedRowsIds, setSelectedRowsIds] = useState([]);
    const [sortChain, setSortChain] = useState([]);
    const [displayedRows, setDisplayedRows] = useState([]);

    useEffect(() => {
        const rowsToFilter = !!filterLambda ? rows.filter(filterLambda) : rows;

        let rowsToSort = [...rowsToFilter]
        rowsToSort.sort((a, b) => {
            for (const link of sortChain) {
                const col = link.column
                const dir = link.direction === SortDirection.ASC ? 1 : -1
                const compFunc = columns[col].sortComp || defaultSortComp
                const sortOn = columns[col].sortOn || (value => value)
                const comp = compFunc(sortOn(a.data[col].value), sortOn(b.data[col].value)) * dir
                if (comp !== 0) return comp;
            }
            return 0;
        })

        setDisplayedRows(rowsToSort)
    }, [sortChain, rows, columns, filterLambda]);

    const handleSelectAll = () => {
        const newSelectAllChecked = !selectAllChecked;
        const newSelectedRowsIds = selectAllChecked ? [] : rows.map((row) => row.id);
        setSelectAllChecked(newSelectAllChecked);
        setSelectedRowsIds(newSelectedRowsIds);
        if (!!onSelectionChange) {
            onSelectionChange(newSelectedRowsIds);
        }
    }

    const handleSelectRow = (id) => {
        let newSelectedRowsIds = [...selectedRowsIds];
        if (selectionType === SelectionType.SINGLE) {
            newSelectedRowsIds = [id];
        } else if (selectionType === SelectionType.MULTIPLE) {
            if (newSelectedRowsIds.includes(id)) {
                newSelectedRowsIds = newSelectedRowsIds.filter(i => i !== id);
            } else {
                newSelectedRowsIds = [...newSelectedRowsIds, id];
            }
        }
        setSelectedRowsIds(newSelectedRowsIds);
        if (!!onSelectionChange) {
            onSelectionChange(newSelectedRowsIds);
        }
    }

    useEffect(() => {
        setSortChain([]);
    }, [rows]);

    const handleSort = (columnIndex) => {
        let inChain = false;
        for (let i = 0; i < sortChain.length; i++) {
            const sortLink = sortChain[i];
            if (sortLink.column === columnIndex) {
                inChain = true;
                if (sortLink.direction === "desc") {
                    setSortChain(prev => prev.filter((_l, index) => index !== i));
                } else {
                    setSortChain(prev => prev.map((link, index) => (index === i) ? { ...link, direction: "desc" } : link));
                }
                break;
            }
        }
        if (!inChain) {
            setSortChain(prev => [...prev, { column: columnIndex, direction: "asc" }]);
        }
    }

    return (
        <div className="bg-white rounded-sm flex-1">
            <table className={`${!!bgColorClass ? bgColorClass : ""} w-full text-gray-700 table-auto border-separate border-spacing-y-2`}>
                <thead className={`${!displayHeaders ? 'hidden' : ''} `}>
                    <tr className={`${!!trColorClass ? trColorClass : ''} `}>
                        {
                            selectionType !== SelectionType.NONE
                                ? (
                                    selectionType === SelectionType.MULTIPLE
                                    ? <th className='sticky top-0 z-5 bg-neutral-100'>
                                        <div className="flex items-center justify-center gap-[0.438rem]">
                                        <InputCheckbox
                                            id="selectAll"
                                            handleClick={handleSelectAll}
                                            isChecked={selectAllChecked}
                                        />
                                    </div></th>
                                    : <th></th>
                                )
                                : null
                        }

                        {
                            columns.map((column, index) =>
                                <th key={index} className={`sticky top-0 z-5 bg-neutral-100 ${column.sortable ? 'cursor-pointer' : ''}`} onClick={() => handleSort(index)}>
                                    <div className="flex items-center justify-center gap-[0.438rem]">
                                        {
                                            column.sortable &&
                                            (
                                                (
                                                    (sortChain.find(l => l.column === index && l.direction === "asc") && <Icons.SvgArrangeAscFill className="h-4" />)
                                                    || (sortChain.find(l => l.column === index && l.direction === "desc") && <Icons.SvgArrangeDescFill className="h-4" />)
                                                ) || <Icons.SvgArrangeDownUpFill color="#292D32" />
                                            )
                                        }
                                        <span className='select-none text-[0.813rem] leading-[0.813rem] font-medium'>{column.label}</span>
                                    </div>
                                </th>
                            )
                        }
                    </tr>
                </thead>

                <tbody className="bg-white">
                    {
                        displayedRows.map((row, index) =>
                            <tr key={index} className={`${!!trColorClass ? trColorClass : 'bg-white'} hover:bg-[#EEEFF2] `}>
                                {
                                    selectionType !== SelectionType.NONE ?
                                        <td className={`${!!roundedClass ? roundedClass : "rounded-tl-[1.25rem] rounded-bl-[1.25rem]"}`}>
                                            <InputCheckbox
                                                id={row.id}
                                                handleClick={() => handleSelectRow(row.id)}
                                                isChecked={selectedRowsIds.includes(row.id)}
                                            />
                                        </td> : null
                                }

                                {
                                    row.data.map((cellData, index) =>
                                        <td key={index} className={`
                                            ${!!tdheightClass ? tdheightClass : ""}
                                            ${!!tdwidthClass ? tdwidthClass : ""}
                                            ${!!tdpaddingClass ? tdpaddingClass : ""}
                                            ${(index === 0 && selectionType === SelectionType.NONE) ? !!roundedClass ? roundedClass : 'rounded-tl-[1.25rem] rounded-bl-[1.25rem]' : ''}
                                            ${index === row.data.length - 1 ? !!roundedClass ? roundedClass : 'rounded-tr-[1.25rem] rounded-br-[1.25rem]' : ''}
                                        `}>
                                            {cellData.value}
                                        </td>
                                    )
                                }
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    );
}

export default Table;
