import React, {useEffect, useState} from 'react';
import { Icons } from "../../../assets/Svgs";
import {Link, useNavigate, useParams} from "react-router-dom";
import { getFullPath } from "../../../router/Router";
import { useTranslation } from "../../../services/i18n/i18nService";
import CustomersService from "../../../services/Customer/CustomersService";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import FieldCopy from "../../../components/Layouts/FieldCopy";
import ButtonBack from "../../../components/Buttons/ButtonBack";
import {format} from "date-fns";

function CustomerView() {
    const { t: _ } = useTranslation();
    const navigate = useNavigate();
    const { custom } = useParams();
    const [ loading, setLoading ] = useState(true);
    const [ customer, setCustomer ] = useState({});

    useEffect(() => {
        CustomersService.getCustomerById(custom)
            .then(customer => {
                setCustomer(customer);
            })
            .catch(err => {
                console.error(err);
                navigate('../', { replace: true });
            })
            .finally(() => {
                setLoading(false);
            })
    }, [custom, navigate]);

    const buttons = [
        {
            label: "Edit",
            className: "bg-white text-black cursor-pointer",
            iconColor: "#000000",
            icon: <Icons.SvgBrushOutline className="h-3" />,
            onClick: () => navigate('../edit/' + custom, { replace: true }),
        },
        {
            label: "Reassign",
            className: "bg-white text-black",
            iconColor: "#000000",
            icon: <Icons.SvgArrowLeftRightOutline className="h-3" />,
        },
        {
            label: "Accounts",
            className: "bg-white text-black",
            iconColor: "#000000",
            icon: <Icons.SvgSidebarDivision className="h-3" />,
            onClick: () => navigate(getFullPath(["ROOT", "CUSTOMERS", "VIEW_CUSTOMER", "ACCOUNT_CUSTOMER"]).replace(":custom", custom))
        },
        {
            label: "Remove",
            className: "bg-white text-[#FF0000]",
            iconColor: "#FF0000",
            icon: <Icons.SvgTrashOutline className="h-3" />,
            onClick: () => {
                CustomersService.deleteCustomer(custom)
                    .then(() => {
                        navigate(getFullPath(["ROOT", "CUSTOMERS"]));
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }
        }
    ]

    const inputs = [
        {
            id: 1,
            name: "name",
            label: "Business Name",
            canCopy: true,
        },
        {
            id: 2,
            name: "manager_name",
            label: "Manager Name",
            canCopy: true,
        },
        {
            id: 3,
            name: "business_mail",
            label: "Business Mail",
            canCopy: true,
        },
        {
            id: 4,
            name: "business_phone",
            label: "Business Phone Number",
            canCopy: true,
        },
        {
            id: 17,
            name: "note",
            label: "Note",
            canCopy: true,
        },
        {
            id: 10,
            name: "address",
            label: "Address",
            canCopy: true,
        },
        {
            id: 11,
            name: "city",
            label: "City",
            canCopy: true,
        },
        {
            id: 12,
            name: "zip_code",
            label: "ZIP/Postal Code",
            canCopy: true,
        },
        {
            id: 13,
            name: "country",
            label: "Country",
            canCopy: true,
        },
        {
            id: 14,
            name: "created_at",
            label: "Added Date",
            canCopy: true,
            formatField: (field, data) => {
                return data[field.name] 
                ? format(new Date(data[field.name]), 'dd/MM/yyyy')
                : null
            }
        },
        {
            id: 15,
            name: "started_at",
            label: "Started Contract Date",
            canCopy: true,
            formatField: (field, data) => {
                return data[field.name] 
                ? format(new Date(data[field.name]), 'dd/MM/yyyy')
                : null
            }
        },
        {
            id: 16,
            name: "accounts_number",
            label: "Accounts Number",
            canCopy: false,
        },
        {
            id: 17,
            name: "assigned_to",
            label: "Assigned to",
            canCopy: false,
            formatField: (field, data) => {
                return data[field.name]
                ? <div className="flex items-center justify-center gap-[1.5rem]">
                    <img src={ data.assigned_to.avatar } className="rounded-full h-8 w-8"  alt="avatar" />
                    <span>{ data.assigned_to.full_name }</span>
                </div>
                : null
            }
        },
    ]

    return (
        <div className="flex flex-col h-screen p-7 gap-7">
            <header className="bg-neutral-100 flex justify-between items-center">
                <div className="text-xl gap-3 flex items-center">
                    <Link to={ getFullPath(["ROOT", "CUSTOMERS"]) } className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("Customers")}</p>
                    </Link>
                    <Icons.SvgArrowRightOutline color="#000000" />
                    {_("View")}
                </div>

                <div className="flex items-center gap-7">
                    <Link to={getFullPath(["ROOT", "CUSTOMERS"])} className="flex text-sm justify-center gap-[0.438rem]">
                        <ButtonBack isFlexible={false}/>
                    </Link>
                </div>
            </header>

            <div className="flex-1 min-h-0 overflow-auto">
                <div className="flex flex-col gap-7 p-7 bg-white rounded-[1.25rem]">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 md:gap-7 justify-center items-center">
                        {
                            buttons.map((button, index) => (
                                <ButtonBase
                                    key={index}
                                    iconColor={button.iconColor}
                                    icon={button.icon}
                                    className={`${button.className} border border-[#D1D5DB] w-full md:w-auto text-sm font-medium py-3 px-6 rounded-full`}
                                    onClick={button.onClick}
                                >
                                    {_(button.label)}
                                </ButtonBase>
                            ))
                        }
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3.5">
                        {
                            inputs.map((input, key) => (
                                <FieldCopy key={key} label={_(input.label)} canCopy={input.canCopy}>
                                    {input.formatField ? input.formatField(input, customer) : customer[input.name]}
                                </FieldCopy>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomerView;