import React, { useEffect, useState } from 'react';
import SelectorDate from "../../../components/Selectors/SelectorDate";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import { Icons } from "../../../assets/Svgs";
import Table from "../../../components/Layouts/Table";
import {Link} from "react-router-dom";
import { useTranslation } from "../../../services/i18n/i18nService";
import { getFullPath } from "../../../router/Router";
import TopWebsiteService from "../../../services/Statistics/StatisticsTopWebsiteService";
import TagTemplate from "../../../components/Tags/TagTemplate";
import {usePopup} from "../../../services/PopupService";
import ButtonBack from "../../../components/Buttons/ButtonBack";
import PopupFilterByView from "../../../components/Popups/TemplateFilter/PopupFilterByView";
import PopupSelectorCalendar from "../../../components/Selectors/PopupSelectorCalendar";

function StatisticsTopWebsites() {
    const { t: _ } = useTranslation();
    const [ listCompleteWeb, setListCompleteWeb ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ selectedView, setSelectedView ] = useState("templates");
    const { addPopup } = usePopup();

    useEffect(() => {
        setLoading(true);
        TopWebsiteService.getTopClicksWebsite()
            .then(datas => {
                setListCompleteWeb([...datas].sort((i1, i2) => (i2.clicks - i1.clicks)));
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    return (
        <div className="p-7 flex flex-col gap-7">
            <header className="bg-neutral-100 flex justify-between items-center border-gray-200">
                <div className="">
                    <div className="text-xl gap-3 flex items-center">
                        <Link to={ getFullPath(["ROOT", "STATISTICS"]) } className="flex justify-center">
                            <p className="hover:underline hover:select-all">{_("Statistics")}</p>
                        </Link>
                        <Icons.SvgArrowRightOutline color="#000000" />
                        {_("Top Websites")}
                    </div>
                </div>

                <div className="">
                    <div className="flex items-center gap-7">


                        <Link to={getFullPath(["ROOT", "STATISTICS"])} className="flex justify-center gap-[0.438rem]">
                            <ButtonBack isFlexible={true} />
                        </Link>

                        <SelectorDate isFlexible={true} onClick={() => addPopup(<PopupSelectorCalendar />)}/>

                        <ButtonBase
                            onClick={() => { addPopup(<PopupFilterByView selectedView={ selectedView } onSelectedViewChange={ setSelectedView } />) }}
                            className="bg-black text-white text-sm py-3.5 px-6 rounded-full"
                            iconColor="white"
                            icon={ <Icons.SvgStatisticFilterOutline /> }
                        >
                            {_("Filter")}
                        </ButtonBase>
                    </div>
                </div>
            </header>

            <div className="flex-1 min-h-0 overflow-auto">
                <div className="flex">
                    <Table
                        tdpaddingClass="py-3.5 px-8"
                        displayHeaders={true}
                        columns={[
                            {
                                label: _("Object"),
                                sortable: true,
                                sortOn: value => value.props.sort_on,
                            },
                            {
                                label: _("Clicks"),
                                sortable: true,
                                sortOn: value => value.props.sort_on,
                            },
                            {
                                label: _("Template"),
                                sortable: true,
                                sortOn: value => value.props.sort_on,
                            },
                        ]}
                        rows={listCompleteWeb.map( lead =>
                            ({
                                data: [
                                    {
                                        value: <div sort_on={ lead.label } className="flex flex-col text-start ">
                                            <span className="text-black">{ lead.label }</span>
                                            <span className="w-14 text-black">{ lead.link }</span>
                                        </div>,
                                    },
                                    {
                                        value: <div sort_on={ lead.clicks } className="flex items-center justify-center gap-[0.438rem]">
                                            <span className="text-[#4B5563]">{ lead.clicks }</span>
                                            <Icons.SvgStatisticClickFill color="#292D32"/>
                                        </div>
                                    },
                                    {
                                        value: <div sort_on={ lead.template } className="flex items-center justify-center gap-[0.438rem]">
                                            <span>{ TagTemplate(lead.template) }</span>
                                        </div>,
                                    },
                                ]
                            })
                        )}
                    />
                </div>
            </div>
        </div>
    );
}

export default StatisticsTopWebsites;