import React, { useEffect, useState } from 'react';
import { useTranslation } from "../../../services/i18n/i18nService";
import { Icons } from "../../../assets/Svgs";
import GeneralPerformService from "../../../services/Statistics/StatisticsGeneralPerformService";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import SelectorDate from "../../../components/Selectors/SelectorDate";
import StatisGrid from "./tops/StatisGrid";
import ViewsOverTime from "./tops/ViewsOverTime";
import StatisTopPerfomers from "./tops/StatisTopPerfomers";
import StatisTopWebsite from "./tops/StatisTopWebsite";
import StatisTopPictures from "./tops/StatisTopPictures";
import StatisTopMedias from "./tops/StatisTopMedias";
import StatisTopFiles from "./tops/StatisTopFiles";
import StatisTopVideos from "./tops/StatisTopVideos";
import StatisTopContacts from "./tops/StatisTopContacts";
import {usePopup} from "../../../services/PopupService";
import PopupFilterByView from "../../../components/Popups/TemplateFilter/PopupFilterByView";
import PopupSelectorCalendar from "../../../components/Selectors/PopupSelectorCalendar";

function Statistics() {
    const { t: _ } = useTranslation();
    const [ generalPerform, setGeneralPerform ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ selectedView, setSelectedView ] = useState("team");
    const { addPopup } = usePopup();

    useEffect(() => {
        setLoading(true);
        GeneralPerformService.getGeneralPerform()
            .then(res => {
                setGeneralPerform(res);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, [generalPerform]);

    return (
        <div className="flex flex-col h-screen">
            <header className="bg-neutral-100 z-10">
                <div className="p-7 flex justify-between">
                    <div className="text-[1.5rem] leading-6 font-medium">{_("Statistics")}</div>
                    <div className="flex items-center gap-7 text-sm">
                        <SelectorDate isFlexible={true} onClick={() => addPopup(<PopupSelectorCalendar />)} />
                        <ButtonBase
                            onClick={() => { addPopup(<PopupFilterByView selectedView={selectedView} onSelectedViewChange={setSelectedView} />) }}
                            className="bg-black text-white py-3.5 px-6 rounded-full"
                            iconColor="white"
                            icon={<Icons.SvgStatisticFilterOutline color="white" className="h-3" />}
                        >
                            {_("Filter")}
                        </ButtonBase>
                    </div>
                </div>
            </header>

            <div className="flex-1 overflow-y-auto bg-neutral-100 gap-7 px-7 flex-col md:flex-row">
                {generalPerform[0] && (
                    <div className="flex flex-col gap-7">
                        <StatisGrid datas={generalPerform[0].chart} grids={generalPerform[0].grids} />

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-7 h-auto">
                            <div className="bg-white p-7 rounded-[0.625rem] flex flex-col shadow-sm">
                                <ViewsOverTime
                                    row
                                    datas={generalPerform[0].viewsOverTime}
                                    value={generalPerform[0].value}
                                    increase={generalPerform[0].increase}
                                    title={_("Profile Views Over Time")}
                                    displayViewSelector={true}
                                />
                            </div>
                            <div className="bg-white p-7 rounded-[0.625rem] flex flex-col shadow-sm">
                                <StatisTopPerfomers datas={generalPerform[0].performs.sort((i1, i2) => (i2.views - i1.views)).slice(0, 5)} />
                            </div>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-7 h-auto">
                            <StatisTopWebsite datas={generalPerform[0].websites.sort((i1, i2) => (i2.clicks - i1.clicks)).slice(0, 5)} />
                            <StatisTopPictures datas={generalPerform[0].pictures.sort((i1, i2) => (i2.views - i1.views)).slice(0, 5)} />
                            <StatisTopMedias datas={generalPerform[0].medias.sort((i1, i2) => (i2.clicks - i1.clicks)).slice(0, 5)} />
                            <StatisTopFiles datas={generalPerform[0].downloads.sort((i1, i2) => (i2.clicks - i1.clicks)).slice(0, 5)} />
                            <StatisTopVideos datas={generalPerform[0].videos.sort((i1, i2) => (i2.views - i1.views)).slice(0, 5)} />
                            <StatisTopContacts datas={generalPerform[0].contacts.sort((i1, i2) => (i2.clicks - i1.clicks)).slice(0, 5)} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Statistics;