import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getFullPath, SUBDOMAIN, SUBDOMAINS } from '../../router/Router';
import AuthService from "../../services/Auth/AuthService";
import Sidebar from "./Sidebar";

const Layout = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const needsLogin = !([getFullPath(["ROOT", "LOGIN"])].includes(pathname));

    // if (needsLogin) {
    //     AuthService.isLoggedIn().then((response) => {
    //         if (!response) {
    //             navigate(getFullPath(["ROOT", "LOGIN"]));
    //         }
    //     });
    // }

    return (
        <div className="xxs:bg-white ss:bg-neutral-100 h-screen w-screen overflow-hidden flex flex-row">
            {
                SUBDOMAIN.path === SUBDOMAINS.APP.path
                    ? null
                    : (needsLogin ? <Sidebar /> : null)
            }

            <div className="flex flex-col flex-1 overflow-hidden">
                <Outlet />
            </div>
        </div>
    )
}

export default Layout;