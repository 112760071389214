import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Icons } from "../../assets/Svgs";
import ButtonBase from "../../components/Buttons/ButtonBase";
import ButtonMobile from "../../components/Buttons/ButtonMobile";
import WidgetFormView from '../../components/Widgets/View/WidgetFormView';
import Widget from "../../components/Widgets/Widget";
import { WIDGET_TYPES } from "../../constants/Widgets";
import { getFullPath } from '../../router/Router';
import { useTranslation } from "../../services/i18n/i18nService";
import { usePopup } from "../../services/PopupService";
import PortfolioService from '../../services/Portfolio/PortfolioService';
import WidgetService from "../../services/Widget/WidgetService";

function Portfolio({ page = null, displayActionBar = true }) {
    const { addPopup } = usePopup();
    const { t: _ } = useTranslation();
    const { link } = useParams();
    const navigate = useNavigate();

    const [exchangeContactWidget, setExchangeContactWidget] = useState(null);
    const exchangeContactWidgetRef = useRef(null);
    const [pageData, setPageData] = useState(page ?? {});
    const loading = Object.keys(pageData).length === 0;

    const handlePortfolioPage = (data) => {
        const widgets = data.widgets;
        WidgetService.findWidgetTypeByName(WIDGET_TYPES.LEAD_CREATION_FORM)
            .then(type => {
                const leadCreationFormExchangeContactWidget = widgets.find(widget =>
                    widget.widget_type === type.id
                    && widget.data.isForExchangeContact
                );
                if (!!leadCreationFormExchangeContactWidget) {
                    setExchangeContactWidget(leadCreationFormExchangeContactWidget);
                    data.widgets = widgets.filter(widget => widget.id !== leadCreationFormExchangeContactWidget.id);
                }
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setPageData(data);
            });
    }

    useEffect(() => {
        if (!!page) {
            handlePortfolioPage(page);
        } else {
            PortfolioService.getPortfolioPage(link)
                .then(page => {
                    handlePortfolioPage({
                        ...page,
                        widgets: page.widgets?.sort((a, b) => (a.order > b.order) ? 1 : -1)
                    });
                    if (page.redirect_to?.clean_label) {
                        navigate(getFullPath(["ROOT", "PROFILE", "VIEW"]).replace(":link", page.redirect_to.clean_label));
                    }
                })
                .catch(err => {
                    console.error(err);
                });
        }
        
    }, [link, page]);

    if (loading) {
        return <div>Loading...</div>;
    }

    const renderHeader = () => (
        <div className="relative top-16 mt-[-4rem] w-full">
            <div className="flex justify-between p-4 items-center">
                <Icons.SvgProfileClientLogo />
                <ButtonMobile
                    textClass="font-normal text-[1rem] leading-6 text-white"
                    className="px-2.5 py-1 bg-black"
                    label="Login"
                    icon={<Icons.SvgProfileClientLogin />}
                />
            </div>
        </div>
    );

    const renderBackgroundImage = () => (
        <div
            className="w-full h-[10rem] top-0"
            style={{
                backgroundImage: `url(${pageData.theme?.background_image})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center"
            }}
        />
    );

    const renderAvatar = () => (
        <div
            className="w-36 h-36 relative top-[-4.5rem] mb-[-4.5rem] rounded-full z-5 border-4 border-white"
            style={{
                backgroundImage: `url(${pageData.user_data?.avatar})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center"
            }}
        />
    );

    const renderUserDetails = () => (
        <div className="flex flex-col gap-2">
            <div className="text-[1.625rem] leading-8 font-semibold">
                {pageData.user_data?.first_name} {pageData.user_data?.last_name}
            </div>
            {pageData.user_data?.role && pageData.user_data?.company && (
                <span className="text-[0.875rem] text-[#6B7280] leading-5 font-medium">
                    {pageData.user_data?.role}
                    {_(" at ")}
                    {pageData.user_data?.company}
                </span>
            )}
        </div>
    );

    const renderWidgets = () => (
        pageData.widgets?.map((widget, index) => (
            <Widget
                key={index}
                widget={widget}
                displayEditAction={false}
                userData={pageData.formating_instructions ? pageData.user_data : null}
                pageUri={link}
            />
        ))
    );

    const renderActionBar = () => (
        <div className="sticky bottom-0 w-full py-6 px-4 shadow-lg text-center bg-white text-black flex justify-between">
            <div className="flex gap-2.5 w-full">
                {
                    !!exchangeContactWidget
                        ? <>
                            <ButtonBase
                                onClick={() => {
                                    if (!!exchangeContactWidgetRef.current) {
                                        exchangeContactWidgetRef.current.handleOpen();
                                    }
                                }}
                                className={`w-7/12 bg-[${pageData.theme?.color || "#000000"}] ${pageData.theme?.color === "#FFFFFF" ? "text-black shadow-lg" : "text-white"} py-3 px-4 rounded-full text-[1rem] leading-6 font-normal`}
                            >
                                {exchangeContactWidget.data.ctaLabel}
                            </ButtonBase>
                            <div className='w-0 overflow-hidden'>
                                <WidgetFormView
                                    ref={exchangeContactWidgetRef}
                                    widget={exchangeContactWidget}
                                    pageUri={link}
                                />
                            </div>
                        </>
                        : null
                }

                <ButtonBase
                    className="w-5/12 bg-[#EEEFF2] text-[#111827] py-3 px-4 rounded-full text-[1rem] leading-6 font-normal"
                >
                    {_("Save Contact")}
                </ButtonBase>
            </div>
        </div>
    );

    return (
        <div className="flex flex-col bg-white h-full overflow-y-auto">
            <div className="flex flex-col mb-6 w-full bg-white items-center text-center">
                {!page && renderHeader()}
                {pageData.theme?.background_image ? renderBackgroundImage() : <div className="w-full h-[10rem] bg-blue-gradient top-0" />}
                {pageData.user_data?.avatar ? renderAvatar() : <div className="w-36 h-36 relative top-[-4.5rem] mb-[-4.5rem] rounded-full z-5 bg-blue-gradient border-4 border-white" />}
                {renderUserDetails()}
            </div>
            <div className="flex flex-col mx-4 mb-4 gap-4 flex-grow">
                {renderWidgets()}
            </div>
            {displayActionBar && renderActionBar()}
        </div>
    );
}

export default Portfolio;