import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import {getFullPath} from "../../../router/Router";
import {Icons} from "../../../assets/Svgs";
import CustomersService from "../../../services/Customer/CustomersService";
import {useTranslation} from "../../../services/i18n/i18nService";
import ButtonBack from "../../../components/Buttons/ButtonBack";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import FieldCopy from "../../../components/Layouts/FieldCopy";
import InputField from "../../../components/Layouts/InputField";
import InputSelected from "../../../components/Layouts/InputSelected";
import InputTextarea from "../../../components/Layouts/InputTextarea";
import {format} from "date-fns";

function CustomerEdit() {

    const { t: _ } = useTranslation();
    const { custom } = useParams();
    const navigate = useNavigate();
    const [ loading, setLoading ] = useState(true);
    const [ customer, setCustomer ] = useState({});

    const [values, setValues] = useState({});

    const cols = [
        {
            inputs: [
                {
                    id: 1,
                    name: "name",
                    type: "text",
                    placeholder: "",
                    label: "Business Name",
                    isModifiable: true,
                    isSelected: false,
                    isTextarea: false,
                },
                {
                    id: 2,
                    name: "manager_name",
                    type: "text",
                    placeholder: "",
                    label: "Manager Name",
                    isModifiable: true,
                    isSelected: false,
                    isTextarea: false,
                },
                {
                    id: 3,
                    name: "business_mail",
                    type: "text",
                    placeholder: "",
                    label: "Business Mail",
                    isModifiable: true,
                    isSelected: false,
                    isTextarea: false,
                },
                {
                    id: 4,
                    name: "business_phone",
                    type: "text",
                    placeholder: "",
                    label: "Business Phone Number",
                    isModifiable: true,
                    isSelected: false,
                    isTextarea: false,
                },
            ]
        },
        {
            inputs: [
                {
                    id: 10,
                    name: "address",
                    type: "text",
                    placeholder: "",
                    label: "Address",
                    isModifiable: true,
                    isSelected: false,
                    isTextarea: false,
                },
                {
                    id: 11,
                    name: "city",
                    type: "text",
                    placeholder: "",
                    label: "City",
                    isModifiable: true,
                    isSelected: false,
                    isTextarea: false,
                },
                {
                    id: 12,
                    name: "zip_code",
                    type: "text",
                    placeholder: "",
                    label: "ZIP/Postal Code",
                    isModifiable: true,
                    isSelected: false,
                    isTextarea: false,
                },
                {
                    id: 13,
                    name: "country",
                    type: "text",
                    placeholder: "",
                    label: "Country",
                    isModifiable: true,
                    isSelected: false,
                    isTextarea: false,
                },
                {
                    id: 15,
                    name: "started_contract_date",
                    type: "date",
                    placeholder: "",
                    label: "Started Contract Date",
                    isModifiable: true,
                    isSelected: false,
                    isTextarea: false,
                },
                {
                    id: 17,
                    name: "note",
                    type: "text",
                    placeholder: "",
                    label: "Note",
                    isModifiable: true,
                    isSelected: false,
                    isTextarea: true,
                },
            ]
        }

    ]

    const formSubmit = (e) => {
        e.preventDefault();
    }

    const onChange = (e) => {
        setValues(v => ({ ...v, [e.target.name]: e.target.value }));
    }

    useEffect(() => {
        setLoading(true);
        CustomersService.getCustomerById(custom)
            .then(customer => {
                setCustomer(customer);
                setValues(v => ({...v, ...customer}));
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, [custom]);

    return (
        <div className="flex flex-col h-screen p-7 gap-7">
            <header className="bg-neutral-100 flex justify-between items-center">
                <div className="text-xl gap-3 flex items-center">
                    <Link to={ getFullPath(["ROOT", "CUSTOMERS"]) } className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("Customers")}</p>
                    </Link>
                    <Icons.SvgArrowRightOutline color="#000000" />
                    <Link to={ getFullPath(["ROOT", "CUSTOMERS", "VIEW_CUSTOMER"]).replace(":custom", customer.id) } className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("View")}</p>
                    </Link>
                    <Icons.SvgArrowRightOutline color="#000000" />
                    {_("Edit")}
                </div>

                <div className="flex items-center gap-7">
                    <Link to={ getFullPath(["ROOT", "CUSTOMERS", "VIEW_CUSTOMER"]).replace(":custom", customer.id) } className="flex text-sm justify-center gap-[0.438rem]">
                        <ButtonBack isFlexible={false}/>
                    </Link>
                    <ButtonBase className="bg-black text-white text-sm py-3.5 px-6 rounded-full" iconColor="white" icon={ <Icons.SvgUserAddOutline className="h-3" /> } >
                        {_("Save the modifications")}
                    </ButtonBase>
                </div>
            </header>

            <div className="flex-1 min-h-0 overflow-auto">
                <div className="flex gap-7 flex-col overflow-x-scroll">
                    <div className="md:flex md:flex-row xs:grid gap-7 p-7 rounded-[1.25rem] bg-white">

                        <div className="w-full bg-white grid grid-cols-2 gap-7">
                            {
                                cols.map((col, index) => (
                                    <div key={index} className="flex flex-col gap-7">
                                        {
                                            col.inputs.map((input) => (
                                                !input.isModifiable
                                                    ? <FieldCopy key={ input.id } label={ input.label } canCopy={ false }>
                                                        {
                                                            input.name === "added_at"
                                                                ? customer.added_at ? format(new Date(customer.added_at), 'dd/MM/yyyy') : "-"
                                                                : customer[input.name]
                                                        }
                                                    </FieldCopy>
                                                    : !input.isSelected
                                                        ? input.isTextarea
                                                            ? <InputTextarea
                                                                gapClass="gap-3.5"
                                                                rows={3}
                                                                name={input.name}
                                                                widthInputClass="w-4/6"
                                                                value={ values[input.name] || '' }
                                                                onChange={ onChange }
                                                                label={ input.label }
                                                                required={false}
                                                                key={ input.id }
                                                            />
                                                            : <InputField
                                                                widthInputClass="w-4/6"
                                                                key={ input.id }
                                                                { ...input }
                                                                value={ input.name === "started_at" ? values[input.name].split("T")[0] : values[input.name] || '' }
                                                                onChange={ onChange }
                                                            />
                                                        : <InputSelected
                                                            widthInputClass="w-4/6"
                                                            key={ input.id }
                                                            name={ input.name }
                                                            label={ input.label }
                                                            bgColorClass="bg-[#F6F7F8]"
                                                            options={["Monthly"]}
                                                            onChange={ onChange }
                                                        />
                                            ))
                                        }
                                        {
                                            index === cols.length - 1 && customer.assigned_to
                                                ? <FieldCopy label="Assigned to" canCopy={false}>
                                                    <div className="flex items-center justify-center gap-[1.5rem]">
                                                        <img src={ customer.assigned_to[0].avatar } className="rounded-full h-8 w-8"  alt="avatar" />
                                                        <span>{ customer.assigned_to[0].full_name }</span>
                                                    </div>
                                                </FieldCopy>
                                                : null
                                        }
                                    </div>
                                ))
                            }
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomerEdit;