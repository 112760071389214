import React, {useEffect, useState} from 'react';
import {useTranslation} from "../../../services/i18n/i18nService";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import {Icons} from "../../../assets/Svgs";
import ViewsOverTime from "../Statistics/tops/ViewsOverTime";
import {Link} from "react-router-dom";
import {getFullPath} from "../../../router/Router";
import DivisionChartsService from "../../../services/Division/DivisionChartsService";
import {usePopup} from "../../../services/PopupService";
import PopupDivisionDelete from "./Popup/PopupDivisionDelete";
import PopupDivisionDuplicate from "./Popup/PopupDivisionDuplicate";
import ButtonFlexible from "../../../components/Buttons/ButtonFlexible";
import InputSearch from "../../../components/Layouts/InputSearch";
import PopupDivisionAdd from "./Popup/PopupDivisionAdd";

const onSearch = (listToSearch, keyword) => {
    if (!keyword) return listToSearch;
    const lowerCaseKeyword = keyword.toLowerCase();
    return listToSearch.filter(item => {
        return item.divisionName.toLowerCase().includes(lowerCaseKeyword);
    });
}

function DivisionsChart() {
    const { t: _ } = useTranslation();
    const [ divisions, setDivisions ] = useState([]);
    const [ listOriginal, setListOriginal ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ selectedWayExport, setSelectedWayExport ] = useState("csv");
    const { addPopup } = usePopup();
    const [ toggle, setToggle ] = useState(false);
    const [ value, setValue ] = useState("");

    useEffect(() => {
        setLoading(true);
        DivisionChartsService.getListCharts()
            .then(res => {
                setDivisions(res);
                setListOriginal(res);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    return (
        <div className="flex flex-col h-screen">
            <header className="bg-neutral-100 z-10">
                <div className="p-7 flex justify-between">
                    <div className="text-[1.5rem] flex items-center gap-3 font-medium">
                        {_("Divisions")}
                        {!loading && <span className="text-[#4B5563] text-[1.25rem] leading-5 font-medium">({divisions.length})</span>}
                    </div>

                    <div className="flex items-center gap-7">
                        {!toggle
                            ? <ButtonFlexible onClick={() => { setToggle((prev) => !prev) }} icon={<Icons.SvgSearchView />}>
                                {_("Search")}
                            </ButtonFlexible>
                            : null
                        }

                        <InputSearch
                            onSearch={onSearch}
                            value={value}
                            setValue={setValue}
                            toggle={toggle}
                            setToggle={setToggle}
                            placeholder={_("Search a template name")}
                            setList={setDivisions}
                            listToSearchOriginal={listOriginal}
                            icon={<Icons.SvgSearchView className="absolute top-1/2 left-3 -translate-y-1/2 h-3" />}
                        />

                        <ButtonBase
                            className="bg-black text-white text-sm py-3.5 px-6 rounded-full"
                            iconColor="white"
                            icon={<Icons.SvgSidebarDivision color="white" className="h-3" />}
                            onClick={() => {
                                addPopup(
                                    <PopupDivisionAdd />
                                )
                            }}
                        >
                            {_("New Division")}
                        </ButtonBase>
                    </div>
                </div>
            </header>

            <div className="flex-1 overflow-auto bg-neutral-100 px-7">
                {divisions ? (
                    <div className="flex flex-col flex-1 gap-7">
                        {
                            divisions.map((template, index) => (
                                <div key={index} className="md:flex bg-white rounded-[0.625rem] justify-between items-center p-7 gap-7 md:h-fit">
                                    <div className="h-fit w-full">
                                        <div className="flex items-center justify-between pb-7">
                                            <div className="text-xl font-semibold">{_(template.template[0].name)}</div>
                                            <div className="text-sm">{template.template[0].nbAssigned} {_("assigned members")}</div>
                                        </div>
                                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-7">
                                            {template.template[0].datas.map((chart, index) => (
                                                <ViewsOverTime
                                                    col
                                                    heightChart="h-[3rem]"
                                                    id={index}
                                                    key={index}
                                                    displayGrid={true}
                                                    datas={chart.viewsOverTime}
                                                    title={_(chart.title)}
                                                    displayViewSelector={false}
                                                    value={chart.value}
                                                    increase={chart.increase}
                                                />
                                            ))}
                                        </div>
                                    </div>

                                    <div className="md:w-2/6 xs:w-full grid gap-3.5">
                                        <Link to={getFullPath(["ROOT", "DIVISIONS", "EDIT_DIVISION"]).replace(":id", template.id)}>
                                            <div className="w-full flex flex-col justify-center align-center gap-7">
                                                <ButtonBase className="bg-white text-black shadow-sm w-full text-[0.813rem] font-medium py-3.5 px-6 rounded-full">
                                                    <Icons.SvgBrushUnderline color="black" className="h-3" />
                                                    {_("Edit Division")}
                                                </ButtonBase>
                                            </div>
                                        </Link>

                                        <div className="w-full flex flex-col justify-center align-center gap-7">
                                            <ButtonBase onClick={() => { addPopup(<PopupDivisionDuplicate />) }} className="bg-white text-black shadow-sm w-full text-[0.813rem] font-medium py-3.5 px-6 rounded-full">
                                                <Icons.SvgTemplateSimCardOutLine className="h-3" />
                                                {_("Duplicate Division")}
                                            </ButtonBase>
                                        </div>

                                        <div className="w-full flex flex-col justify-center align-center gap-7">
                                            <ButtonBase onClick={() => { addPopup(<PopupDivisionDelete />) }} className="bg-white text-[#FF0000] shadow-sm w-full text-[0.813rem] font-medium py-3.5 px-6 rounded-full">
                                                <Icons.SvgTrashOutline color="#FF0000" className="h-3" />
                                                {_("Delete Division")}
                                            </ButtonBase>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default DivisionsChart;