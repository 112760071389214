import React from 'react';
import {Icons} from "../../../assets/Svgs";
import PopupMobile from "../../../components/Popups/PopupMobile";
import {useTranslation} from "../../../services/i18n/i18nService";
import {usePopup} from "../../../services/PopupService";

function PopupDocDownload({name, link}) {
    const { t: _ } = useTranslation();
    const { clearAllPopups } = usePopup();

    return (
        <PopupMobile
            icon={<Icons.SvgProfilePopupDelete/>}
            title={_("Would you like to download this document?")}
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-[#111827]",
                        className: "border border-[#D1D5DB]",
                        onClick: () => {
                            clearAllPopups()
                        },
                    },
                    {
                        label: _("Download"),
                        background: "bg-[#0400BD]",
                        color: "text-white",
                        onClick: () => {
                            const anchor = document.createElement('a');
                            anchor.href = link;
                            anchor.download = name;
                            document.body.appendChild(anchor);
                            anchor.click();
                            document.body.removeChild(anchor);
                            clearAllPopups()
                        },
                    },
                ]
            }>
            <div className="font-normal text-[#6B7280] leading-5 text-[0.875rem] truncate w-96">{link}</div>
        </PopupMobile>
    );
}

export default PopupDocDownload;