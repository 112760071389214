import React, {useEffect, useState} from 'react';
import PopupBase from "../PopupBase";
import {usePopup} from "../../../services/PopupService";
import {useTranslation} from "../../../services/i18n/i18nService";
import IPhoneWrapper from "../../Layouts/IPhoneWrapper";
import TeamsService from "../../../services/Team/TeamService";
import Portfolio from "../../../pages/Mobile/Portfolio";

function PopupMemberPreview({id}) {
    const { clearAllPopups } = usePopup();
    const { t: _ } = useTranslation();
    const [ loading, setLoading ] = useState(true);
    const [ team, setTeam ] = useState();

    useEffect(() => {
        setLoading(true);
        TeamsService.getTeamMemberById(id)
            .then(resTeam => {
                setTeam(resTeam);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, [id]);

    return (
        <PopupBase
            footerButtons={
                [
                    {
                        label: _("Close the preview"),
                        background: "bg-white",
                        color: "text-black",
                        onClick: clearAllPopups,
                    },
                ]
            }>
            <div className="px-40">
                <IPhoneWrapper>
                    {team && <Portfolio page={{ widgets: [], user_data: { avatar: team.avatar } }} displayActionBar={true} />}
                </IPhoneWrapper>
            </div>

        </PopupBase>
    );
}

export default PopupMemberPreview;