import React, {useEffect, useState} from 'react';
import {useTranslation} from "../../../services/i18n/i18nService";
import {Link, useNavigate, useParams} from "react-router-dom";
import ContractsService from "../../../services/Contract/ContractsService";
import {getFullPath} from "../../../router/Router";
import {Icons} from "../../../assets/Svgs";
import ButtonBack from "../../../components/Buttons/ButtonBack";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import FieldCopy from "../../../components/Layouts/FieldCopy";
import InputField from "../../../components/Layouts/InputField";
import InputSelected from "../../../components/Layouts/InputSelected";
import InputTextarea from "../../../components/Layouts/InputTextarea";
import {format} from "date-fns";

function ContractEdit() {

    const { t: _ } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const [ loading, setLoading ] = useState(true);
    const [ contract, setContract ] = useState({});

    const [values, setValues] = useState({
        contract_ref: "",
        business_name: "",
        currency: '',
        pay_plan: '',
        monthly_payment: '',
        annual_payment: '',
        total_earnings: '',
        added_at: '',
        started_at: '',
        note: '',
    });


    const inputs = [
        {
            id: 1,
            name: "contract_ref",
            type: "text",
            placeholder: "",
            label: "Contract Reference",
            isSelected: false,
        },
        {
            id: 2,
            name: "business_name",
            type: "text",
            placeholder: "",
            label: "Business Name",
            isSelected: true,
        },
        {
            id: 3,
            name: "currency",
            type: "text",
            placeholder: "",
            label: "Currency",
            isSelected: true,
        },
        {
            id: 4,
            name: "pay_plan",
            type: "text",
            placeholder: "",
            label: "Pay Plan",
            canCopy: true,
            isSelected: true,
        },
        {
            id: 5,
            name: "monthly_payment",
            type: "text",
            placeholder: "",
            label: "Monthly Recurrent Payment",
            isSelected: false,
        },
        {
            id: 6,
            name: "annual_payment",
            type: "text",
            placeholder: "",
            label: "Annual Recurrent Payment",
            isSelected: false,
        },
        {
            id: 7,
            name: "total_earnings",
            type: "text",
            placeholder: "",
            label: "Total earnings from the beginning",
            isSelected: false,
        },
    ]

    const formSubmit = (e) => {
        e.preventDefault();
    }

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        setLoading(true);
        ContractsService.getContractById(id)
            .then(resContract => {
                setContract(resContract);
                setValues({...values, ...contract});
            })
            .catch(err => {
                console.error(err);
                navigate('../', { replace: true });
            })
            .finally(() => {
                setLoading(false);
            })
    }, [contract]);

    return (
        <form onSubmit={ formSubmit } className="p-7 flex flex-col gap-7">
            <header className="bg-neutral-100 flex justify-between items-center border-gray-200 flex-1 min-h-0 overflow-auto">
                <div className="text-xl gap-3 flex items-center">
                    <Link to={ getFullPath(["ROOT", "CONTRACTS"]) } className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("Contracts")}</p>
                    </Link>
                    <Icons.SvgArrowRightOutline color="#000000" />
                    <Link to={ getFullPath(["ROOT", "CONTRACTS", "VIEW_CONTRACT"]).replace(":id", id) } className="flex justify-center gap-[0.438rem]">
                        <p className="hover:underline hover:select-all">{_("View")}</p>
                    </Link>
                    <Icons.SvgArrowRightOutline color="#000000" />
                    {_("Edit")}
                </div>

                <div className="flex items-center gap-7">
                    <Link to={ getFullPath(["ROOT", "CONTRACTS", "VIEW_CONTRACT"]).replace(":id", id) } className="flex text-sm justify-center gap-[0.438rem]">
                        <ButtonBack isFlexible={false}/>
                    </Link>
                    <ButtonBase className="bg-black text-white text-sm py-3.5 px-6 rounded-full" iconColor="white" icon={ <Icons.SvgUserAddOutline className="h-3" /> } >
                        {_("Save the modifications")}
                    </ButtonBase>
                </div>
            </header>

            <div className="flex-1 min-h-0 overflow-auto">
                <div className="flex gap-7 flex-col overflow-x-scroll">
                    <div className="md:flex md:flex-row xs:grid gap-7 p-7 rounded-[1.25rem] bg-white">

                        <div className="w-full bg-white grid grid-cols-2 gap-7">
                            <div className="flex flex-col gap-7">
                                {
                                    inputs.map((input) => (
                                        !input.isSelected
                                            ?   <InputField
                                                widthInputClass="w-4/6"
                                                key={ input.id }
                                                { ...input }
                                                value={ values[input.name] }
                                                onChange={ onChange }
                                            />
                                            :   <InputSelected
                                                name={input.name}
                                                widthInputClass="w-4/6"
                                                key={ input.id }
                                                label={ input.label }
                                                bgColorClass="bg-[#F6F7F8]"
                                                options={["Monthly"]}
                                                onChange={ onChange }
                                            />
                                    ))
                                }
                            </div>

                            <div className="flex flex-col gap-7">
                                <FieldCopy
                                    name="added_at"
                                    label="Added Date"
                                    canCopy={ false }
                                >
                                    { contract.added_at ? format(new Date(contract.added_at), 'dd/MM/yyyy') : '-' }
                                </FieldCopy>

                                <InputField
                                    type="date"
                                    name="started_at"
                                    widthInputClass="w-4/6"
                                    label="Started Contract Date"
                                    value={ values["started_at"].split("T")[0] }
                                    onChange={ onChange }
                                />

                                <InputTextarea
                                    gapClass="gap-3.5"
                                    rows={3}
                                    name="note"
                                    widthInputClass="w-4/6"
                                    value={ values["note"] }
                                    onChange={ onChange }
                                    label="Note"
                                    required={false}
                                />

                                {
                                    contract.assigned_to
                                        ? <FieldCopy label="Assigned to" canCopy={false}>
                                            <div className="flex items-center justify-center gap-[1.5rem]">
                                                <img src={ contract.assigned_to[0].avatar } className="rounded-full h-8 w-8"  alt="avatar" />
                                                <span>{ contract.assigned_to[0].full_name }</span>
                                            </div>
                                        </FieldCopy>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default ContractEdit;