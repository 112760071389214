import React, {useEffect, useState} from 'react';
import ButtonBase from "../../../components/Buttons/ButtonBase";
import { Icons } from "../../../assets/Svgs";
import { useTranslation } from "../../../services/i18n/i18nService";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getFullPath } from "../../../router/Router";
import LeadsService from "../../../services/Leads/LeadsService";
import { format } from "date-fns";
import ButtonDropDown from "../../../components/Buttons/ButtonDropDown";
import FieldCopy from "../../../components/Layouts/FieldCopy";
import ButtonBack from "../../../components/Buttons/ButtonBack";
import PopupSelectTag from "../../../components/Popups/AssignTags/PopupSelectTag";
import {usePopup} from "../../../services/PopupService";
import PopupSelectMemberToReassign from "../../../components/Popups/ReassignLead/PopupSelectMemberToReassign";
import PopupSendMailToLeadSelected from "../../../components/Popups/Mailing/PopupSendMailToLeadSelected";
import PopupDeletePersonSelected from "../../../components/Popups/DeletePerson/PopupDeletePersonSelected";
import PopupSelectorExportLeads from "../../../components/Popups/ExportLead/PopupSelectorExportLeads";

function LeadView() {
    const { t: _ } = useTranslation();
    const { addPopup } = usePopup();
    const navigate = useNavigate();
    const { id } = useParams();
    const [ loading, setLoading ] = useState(true);
    const [ lead, setLead ] = useState({});
    const [ listLeads, setListLeads ] = useState([]);
    const [ selectedWayExport, setSelectedWayExport ] = useState("csv");

    useEffect(() => {
        setLoading(true);
        LeadsService.getLeadById(parseInt(id))
            .then(lead => {
                setLead(lead);
            })
            .catch(err => {
                console.error(err);
                navigate('../', { replace: true });
            })
            .finally(() => {
                setLoading(false);
            })
    }, [lead]);

    useEffect(() => {
        setLoading(true);
        LeadsService.getLeads()
            .then(leads => {
                setListLeads(leads);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    const links = [
        {
            id: 0,
            icon: <Icons.SvgLeadsTagOutline className="h-3" />,
            iconColor: "#000000",
            label: "Add a tag",
            onClick: () => {
                addPopup( <PopupSelectTag /> )
            },
        },
        {
            id: 1,
            icon: <Icons.SvgBrushUnderline className="h-3" />,
            iconColor: "#000000",
            label: "Edit",
            onClick: () => navigate('../edit/' + id, { replace: true }),
        },
        {
            id: 2,
            icon: <Icons.SvgLeadsReassign className="h-3" />,
            iconColor: "#000000",
            label: "Re-assign",
            onClick: () => {
                addPopup( <PopupSelectMemberToReassign members={listLeads} /> )
            },
        },
        {
            id: 3,
            icon: <Icons.SvgLeadsMailOutline className="h-3" />,
            iconColor: "#000000",
            label: "Send a mail",
            onClick: () => {
                addPopup( <PopupSendMailToLeadSelected /> )
            },
        },
        {
            id: 4,
            icon: <Icons.SvgTrashOutline className="h-3" />,
            iconColor: "#FF0000",
            label: "Delete",
            onClick: () => {
                addPopup( <PopupDeletePersonSelected objectToDelete="leads" listChecked={listLeads} /> )
            },
        }
    ]

    return <div className="p-7 flex flex-col gap-7">
        <header className="bg-neutral-100 flex justify-between items-center border-gray-200">
            <div className="text-xl gap-3 flex items-center">
                <Link to={ getFullPath(["ROOT", "LEADS"]) } className="flex justify-center">
                    <p className="hover:underline hover:select-all">{_("Leads")}</p>
                </Link>
                <Icons.SvgArrowRightOutline color="#000000" />
                <p>{_("View")}</p>
            </div>

            <div className="flex items-center gap-7">
                <Link to={ getFullPath(["ROOT", "LEADS"])} className="flex justify-center gap-[0.438rem]">
                    <ButtonBack isFlexible={true}/>
                </Link>

                <div>
                    <ButtonDropDown links={ links } />
                </div>

                <ButtonBase onClick={() => {
                    addPopup(
                        <PopupSelectorExportLeads listChecked={[parseInt(id)]} hasSelectorVef={true} type="leadsview" selectedWayExport={ selectedWayExport } onSelectedWayExport={ setSelectedWayExport } />)}}
                            className="bg-black text-white text-sm py-3.5 px-6 rounded-full"
                            iconColor="white"
                            icon={ <Icons.SvgLeadsExportOutline className="h-3" />} >
                    {_("Export")}
                </ButtonBase>
            </div>
        </header>

        <div className="flex-1 min-h-0 overflow-auto">
            <div className="flex gap-7 flex-col overflow-x-scroll">
                <div className="md:flex md:flex-row xs:grid gap-7 p-7 rounded-[1.25rem] bg-white">
                    <div className="w-full bg-white grid grid-cols-2">
                        <div className="flex flex-col gap-7">
                            {
                                lead?.data?.map((item, index) => (
                                    <FieldCopy key={index} label={item.key} canCopy={true}>
                                        {item.value}
                                    </FieldCopy>
                                ))
                            }
                        </div>

                        <div className="flex flex-col gap-7">
                            <FieldCopy label="Note" canCopy={true} >
                                { lead.note }
                            </FieldCopy>

                            <FieldCopy label="Tag" canCopy={false} >
                                <ButtonBase className="text-sm leading-3 bg-[#D1D5DB] text-[#4B5563] px-4 py-1.5 gap-[0.438rem] shadow-sm flex justify-center items-center rounded-[0.625rem] cursor-pointer">
                                    { lead.template }
                                </ButtonBase>
                            </FieldCopy>

                            {
                                lead.created_at
                                    ? <FieldCopy label="Connection date" canCopy={true} >
                                        { format(new Date(lead.created_at), 'dd/MM/yyyy') } at  { format(new Date(lead.created_at), 'hh:mm') }
                                    </FieldCopy>
                                    : null
                            }

                            <FieldCopy label="Connected with" canCopy={false}>
                                <div className="flex gap-6 items-center">
                                    <img src={lead.avatar} className="rounded-full h-8 w-8"  alt="avatar" />
                                    <span>{lead.connected}</span>
                                </div>
                            </FieldCopy>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default LeadView;