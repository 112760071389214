import { useEffect, useRef, useState } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import { Icons } from "../../../assets/Svgs";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import ButtonFlexible from "../../../components/Buttons/ButtonFlexible";
import InputField from "../../../components/Layouts/InputField";
import RuleHorizontal from "../../../components/Layouts/RuleHorizontal";
import RuleVertical from "../../../components/Layouts/RuleVertical";
import PopupAssignNewAdminAccess from "../../../components/Popups/AdminAccess/PopupAssignNewAdminAccess";
import PopupConfirmAssignNewAdminAccess from "../../../components/Popups/AdminAccess/PopupConfirmAssignNewAdminAccess";
import PopupConfirmDeleteAdminAccess from "../../../components/Popups/AdminAccess/PopupConfirmDeleteAdminAccess";
import PopupDeleteAdminAccess from "../../../components/Popups/AdminAccess/PopupDeleteAdminAccess";
import PopupMemberPreview from "../../../components/Popups/MemberPreview/PopupMemberPreview";
import PopupResetPasswdSelectedTeam from "../../../components/Popups/Password/PopupResetPasswdSelectedTeam";
import PopupSelectorCalendar from "../../../components/Selectors/PopupSelectorCalendar";
import SelectorDate from "../../../components/Selectors/SelectorDate";
import { DASHBOARD_TEMP_TEAMS_DATA } from '../../../constants/DatasConstants';
import { getFullPath } from "../../../router/Router";
import { useTranslation } from "../../../services/i18n/i18nService";
import { usePopup } from "../../../services/PopupService";
import TeamsService from "../../../services/Team/TeamService";
import TemplateService from "../../../services/Template/TemplateService";
import UserService from '../../../services/User/UserService';
import StatisGrid from "../Statistics/tops/StatisGrid";
import TeamFormReference from "./TeamFormReference";
import FileService from '../../../services/FileService';

const team = DASHBOARD_TEMP_TEAMS_DATA;

function TeamMemberView() {
    /* Hooks */
    const { t: _ } = useTranslation();
    const { id } = useParams();
    const { addPopup } = usePopup();
    const navigate = useNavigate();

    /* States */
    const [loading, setLoading] = useState(false);
    const [teamMember, setTeamMember] = useState(null);
    console.log(teamMember);
    const [templates, setTemplates] = useState([]);
    const avatarInputRef = useRef(null);

    /* Utils */
    const isLoading = !teamMember || loading;
    const hasAdminAccess = teamMember?.membership?.is_manager;

    const memberInfoFields = [
        {name: "First Name", key: "first_name", 
            valueGetter: (teamMember) => teamMember.first_name,
            valueSetter: (teamMember, value) => ({ ...teamMember, first_name: value })},
        {name: "Last Name", key: "last_name", 
            valueGetter: (teamMember) => teamMember.last_name,
            valueSetter: (teamMember, value) => ({ ...teamMember, last_name: value })},
        {name: "Mail", key: "email", 
            valueGetter: (teamMember) => teamMember.email,
            valueSetter: (teamMember, value) => ({ ...teamMember, email: value })},
        {name: "Job Title", key: "job_title",
            valueGetter: (teamMember) => teamMember.membership?.job_title,
            valueSetter: (teamMember, value) => ({ ...teamMember, membership: { ...(teamMember.membership || {}), job_title: value } })},

        {name: "Phone Number", key: "phone_number"},
        {name: "Office Phone Number", key: "office_phone_number"},
        // {name: "Company", key: "company"},
        {name: "WhatsApp Phone", key: "whatsapp_phone"},
        {name: "WhatsApp Message", key: "whatsapp_message"},
        {name: "LinkedIn Profile", key: "linkedin_profile"},
        {name: "Calendly Link", key: "calendly_link"},
        {name: "Facebook Link", key: "facebook_link"},
        {name: "Instagram Link", key: "instagram_link"},
        {name: "X Link", key: "x_link"},
        {name: "TikTok Link", key: "tiktok_link"},
        {name: "PayPal Link", key: "paypal_link"}
    ]

    const memberInfoValueOf = (field, teamMember) => {
        if (field.valueGetter) {
            return field.valueGetter(teamMember);
        }
        return teamMember.info?.find(i => i.key === field.key)?.value;
    }

    /* Handlers */
    const handleInfoChange = (field, value) => {
        setTeamMember(prevTeamMember => {
            let updatedTeamMember = {...prevTeamMember};
            if (field.valueSetter) {
                updatedTeamMember = field.valueSetter(updatedTeamMember, value);
            } else {
                if (updatedTeamMember.info?.find(i => i.key === field.key) === undefined) {
                    updatedTeamMember = {
                        ...updatedTeamMember,
                        info: [
                            ...updatedTeamMember.info || [],
                            { key: field.key, value },
                        ],
                    }
                } else {
                    updatedTeamMember.info = updatedTeamMember.info.map(i => 
                        i.key === field.key 
                            ? { ...i, value } 
                            : i
                    );
                };
            }
            return updatedTeamMember;
        });
    };

    const handlePopupConfirmAssignAdminAccess = () => {
        addPopup(
            <PopupConfirmAssignNewAdminAccess />
        )
    }

    const handlePopupAssignAdminAccess = () => {
        addPopup(
            <PopupAssignNewAdminAccess assignNewAdmin={handlePopupConfirmAssignAdminAccess} />
        )
    }

    const handlePopupConfirmDeleteAdminAccess = () => {
        addPopup(
            <PopupConfirmDeleteAdminAccess />
        )
    }

    const handlePopupDeleteAdminAccess = () => {
        addPopup(
            <PopupDeleteAdminAccess confirmDelete={handlePopupConfirmDeleteAdminAccess} />
        )
    }

    const handlePictureChange = (files) => {
        const file = files[0];
        avatarInputRef.current && (avatarInputRef.current.value = "");
        if (!file) return;
        FileService.loadFileBase64(file)
            .then(data => {
                setTeamMember(prevTeamMember => ({
                    ...prevTeamMember,
                    avatar: data,
                }))
            })
            .catch(err => {
                console.error(err);
            })
    }

    const handleSaveMembership = (id) => {
        const payload = {
            id: id === undefined ? teamMember.membership.id : id,
            is_manager: teamMember.membership?.is_manager || false,
            job_title: teamMember.membership?.job_title,
            template: teamMember.membership?.template,
        }
        UserService.updateMembership(payload.id, payload)
            .then(membership => {
                setTeamMember(prevTeamMember => ({
                    ...prevTeamMember,
                    membership: membership
                }))
            })
            .catch(err => {
                console.error(err)
            })
    }

    const handleSaveMember = () => {
        const payload = {
            ...(teamMember.avatar?.startsWith("data:image") ? {avatar: teamMember.avatar} : {}),
            first_name: teamMember.first_name,
            last_name: teamMember.last_name,
            email: teamMember.email,
            info: teamMember.info || [],
        }
        if (teamMember.id === undefined) {
            console.log("Create user in company");
            UserService.createUserInCurrentCompany(payload)
                .then(member => {
                    setTeamMember(prev => ({
                        ...member,
                        membership: prev.membership
                    }))
                    handleSaveMembership(member.membership.id)
                    navigate(getFullPath(["ROOT", "TEAM", "VIEW_TEAM_MEMBER"]).replace(":id", member.id))
                })
                .catch(err => {
                    console.error(err)
                })
        } else {
            UserService.updateUser(teamMember.id, payload)
                .then(member => {
                    setTeamMember(prev => ({
                        ...member,
                        membership: prev.membership
                    }))
                    handleSaveMembership()
                })
                .catch(err => {
                    console.error(err)
                })
        }
    }

    /* Effects */
    useEffect(() => {
        if (id !== undefined) {
            TeamsService.getTeamMemberById(parseInt(id))
                .then((tM) => {
                    setTeamMember(tM);
                })
                .catch(err => {
                    console.error(err);
                })
        } else {
            setTeamMember({});
        }
    }, [id]);

    useEffect(() => {
        TemplateService.getTemplates()
            .then(t => {
                setTemplates(t);
            })
            .catch(err => {
                console.error(err);
            })
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="flex flex-col p-7 gap-7 overflow-hidden">
            <header className="flex bg-neutral-100 justify-between items-center border-gray-200">
                <div className="text-xl gap-3 flex items-center">
                    <Link to={getFullPath(["ROOT", "TEAM"])} className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("Team")}</p>
                    </Link>
                    <Icons.SvgArrowRightOutline color="#000000" />
                    <p className="whitespace-nowrap">{_("View this member")}</p>
                </div>

                <div className="flex items-center gap-7">
                    <Link to={getFullPath(["ROOT", "TEAM"])} className="flex justify-center">
                        <ButtonFlexible icon={<Icons.SvgArrowReturnOutline />}>
                            {_("Back")}
                        </ButtonFlexible>
                    </Link>

                    <SelectorDate isFlexible={true} onClick={() => addPopup(<PopupSelectorCalendar />)} />

                    <ButtonFlexible onClick={() => {
                            addPopup(<PopupResetPasswdSelectedTeam teamMembers={[teamMember]} />)
                        }}
                        icon={<Icons.SvgTeamPasswordCheckOutline />}
                    >
                        {_("Password")}
                    </ButtonFlexible>

                    <ButtonFlexible onClick={() => { addPopup(<PopupMemberPreview id={id} />) }} icon={<Icons.SvgTeamEyeOutline />}>
                        {_("Preview this profile")}
                    </ButtonFlexible>

                    <Link to={getFullPath(["ROOT", "TEAM", "VIEW_TEAM_MEMBER_STATISTICS"]).replace(":id", id)} className="flex justify-center">
                        <ButtonFlexible icon={<Icons.SvgSidebarStatistics />}>
                            {_("Statistics")}
                        </ButtonFlexible>
                    </Link>

                    <ButtonBase className="bg-black text-white text-sm py-3.5 px-6 rounded-full" iconColor="#ffffff" icon={<Icons.SvgTeamSaveUserOutline />} 
                                onClick={handleSaveMember}
                    >
                        {_("Save this member")}
                    </ButtonBase>
                </div>
            </header>

            <div className="flex flex-col p-7 gap-7 rounded-[1.25rem] bg-white overflow-auto">
                <div className="flex w-full gap-7">
                    <label className="flex flex-col gap-3.5 flex-1">
                        <span className="text-center text-sm font-medium after:content-['*'] after:ml-0 after:text-red-500">
                            {_("Profile Picture")}
                        </span>

                        <input
                            type="file"
                            className="hidden"
                            ref={ avatarInputRef }
                            onChange={ (e) => { handlePictureChange(e.target.files) } }
                            accept="image/*"
                        />
                        <div className="flex flex-col gap-7 justify-center items-center">
                            {
                                !!teamMember.avatar
                                    ? <img className="w-[10rem] h-[10rem] md:w-[12.5rem] md:h-[12.5rem] lg:w-[14.5rem] lg:h-[14.5rem] rounded-full" src={teamMember.avatar} alt="member avatar" />
                                    : <div className="w-[10rem] h-[10rem] md:w-[12.5rem] md:h-[12.5rem] lg:w-[14.5rem] lg:h-[14.5rem] bg-[#EEEFF2] rounded-full" alt="member avatar placeholder" />
                            }

                            <ButtonBase
                                onClick={ () => { avatarInputRef?.current?.click() } }
                                className="bg-white w-fit text-black border border-[#EEEFF2] shadow-sm text-sm py-3.5 px-6 rounded-full"
                            >
                                {!!teamMember.avatar ? _("Modify the picture") : _("Add a new picture")}
                            </ButtonBase>
                        </div>
                    </label>

                    {teamMember.id !== undefined && <>
                        <RuleVertical />

                        <TeamFormReference teamMemberId={teamMember.id} />
                    </>}
                </div>

                <RuleHorizontal />

                {
                    !hasAdminAccess
                        ? <div className="flex flex-col gap-3.5 items-center justify-center">
                            <p className='text-sm' onClick={() => {}}>
                                {_("This team member has currently no admin access.")}
                            </p>
                            <ButtonBase onClick={handlePopupAssignAdminAccess} className="hover:bg-orange-gradient text-sm hover:px-6 bg-orange-gradient w-fit text-white py-3.5 px-6 rounded-full" icon={<Icons.SvgTeamUserOctagon className="h-3" />}>
                                {_("Assign a new admin access")}
                            </ButtonBase>
                        </div>
                        : <div className="flex flex-col gap-3.5 items-center justify-center">
                            <p className='text-sm' onClick={() => {}} >
                                {_("This team member has currently an admin access.")}
                            </p>
                            <ButtonBase onClick={handlePopupDeleteAdminAccess} className="hover:bg-white text-sm hover:px-6 w-fit text-[#EC0000] border border-[#F6F7F8] py-3.5 px-6 rounded-full" iconColor="#EC0000" icon={<Icons.SvgTrashOutline className="h-3" />}>
                                {_("Delete the admin access for this user")}
                            </ButtonBase>
                        </div>
                }

                <RuleHorizontal />

                {
                    team.datas
                        ? <div className="flex flex-col gap-3.5 items-center justify-center">
                            <StatisGrid datas={team.datas[0].chart} grids={team.datas[0].grids} />
                        </div>
                        : null
                }

                <RuleHorizontal />

                <div className="flex flex-wrap gap-7">
                    <label className="flex flex-col gap-3.5 basis-1/3 flex-1">
                        <span className={"text-sm font-medium flex text-[#4B5563]"}>
                            { _("Template") }
                        </span>

                        <div className={`flex rounded-md shadow-sm`}>
                            <select className={ "w-full text-gray-900 text-sm block p-2.5 pr-9 rounded-lg bg-[#F6F7F8]" }
                                    value={teamMember.membership?.template || ""}
                                    onChange={ e => {
                                        const templateId = e.target.value === "" 
                                            ? null
                                            : e.target.value;
                                        setTeamMember(prevTeamMember => ({
                                            ...prevTeamMember,
                                            membership: {
                                                ...(prevTeamMember.membership || {}),
                                                template: templateId
                                            }
                                        }))
                                    }}
                            >
                                <option value="">{ _("Select a template") }</option>
                                {
                                    templates.map((option, index) => (
                                        <option key={ index } value={option.id}>{ option.title }</option>
                                    ))
                                }
                            </select>
                        </div>
                    </label>

                    {
                        memberInfoFields.map((field, index) => (
                            <InputField
                                className="basis-1/3 flex-1"
                                key={index}
                                name={field.key}
                                widthInputClass="w-full md:w-4/6"
                                value={memberInfoValueOf(field, teamMember) || ""}
                                label={field.name}
                                onChange={(e) => handleInfoChange(field, e.target.value)}
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default TeamMemberView;