import React from 'react';
import { Icons } from "../../../../assets/Svgs";
import { Link } from "react-router-dom";
import { getFullPath } from "../../../../router/Router";
import {useTranslation} from "../../../../services/i18n/i18nService";
import Table, {SelectionType} from "../../../../components/Layouts/Table";

function StatisTopPerfomers({datas}) {
    const { t: _ } = useTranslation();

    return (
        <>
            <header className="flex justify-between items-center">
                <div className="text-sm flex gap-1.5 items-center">
                    <p>{_("Top 5 Performers")}</p>
                    <Icons.SvgStatisticEyeFill />
                </div>
                <Link to={getFullPath(["ROOT", "STATISTICS", "PERFORMER"])} className="flex justify-center gap-[0.438rem]">
                    <div className="text-sm flex gap-[0.188rem] items-center hover:underline hover:select-all">
                        {_("View")}
                        <Icons.SvgStatisticCirclePlusFill color="#292D32" />
                    </div>
                </Link>
            </header>

            {
                datas && (
                    <Table
                        displayHeaders={false}
                        bgColorClass="bg-white"
                        columns={[{}, {}, {}]}
                        rows={datas.map((perform, index) => ({
                            data: [
                                { value: <div>{index + 1}</div> },
                                {
                                    value: (
                                        <div className="flex items-center gap-[1.5rem]">
                                            <img src={perform.avatar} className="rounded-full h-7 w-7" alt="avatar" />
                                            <span>{perform.full_name}</span>
                                        </div>
                                    )
                                },
                                { value: <div className="flex justify-center items-center">{perform.views}</div> },
                            ],
                        }))}
                    />
                )
            }
        </>
    );
}

export default StatisTopPerfomers;