import React, { useState } from 'react';
import { useTranslation } from "../../../services/i18n/i18nService";
import { Icons } from "../../../assets/Svgs";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import ButtonGradient from "../../../components/Buttons/ButtonGradient";
import LayoutIntergration from "./LayoutIntergration";
import LayoutSubscription from "./LayoutSubscription";
import LayoutTutorialVideos from "./LayoutTutorialVideos";
import LayoutContactForm from "./LayoutContactForm";

function Settings() {
    const { t: _ } = useTranslation();
    const [selectedMode, setSelectedMode] = useState("Integrations");

    const layouts = {
        Integrations: <LayoutIntergration />,
        Subscription: <LayoutSubscription />,
        TutorialVideos: <LayoutTutorialVideos />,
        ContactForm: <LayoutContactForm />
    };

    const navs = [
        {
            label: "Integrations",
            mode: "Integrations",
            iconUnactive: <Icons.SvgTemplatesExport className="h-3" />,
            iconActive: <Icons.SvgTemplatesExportGradient className="h-3" />,
        },
        {
            label: "Subscription",
            mode: "Subscription",
            iconUnactive: <Icons.SvgTemplatesExport className="h-3" />,
            iconActive: <Icons.SvgTemplatesExportGradient className="h-3" />,
        },
        {
            label: "Tutorial Videos",
            mode: "TutorialVideos",
            iconUnactive: <Icons.SvgTemplatesExport className="h-3" />,
            iconActive: <Icons.SvgTemplatesExportGradient className="h-3" />,
        },
        {
            label: "Contact Form",
            mode: "ContactForm",
            iconUnactive: <Icons.SvgTemplatesExport className="h-3" />,
            iconActive: <Icons.SvgTemplatesExportGradient className="h-3" />,
        }
    ];

    return (
        <div className="flex flex-col h-screen">
            <header className="bg-neutral-100 p-7 flex justify-between items-center z-10">
                <div className="text-[1.5rem] font-medium">
                    {_("Settings")}
                </div>
            </header>

            <div className="flex flex-col overflow-auto bg-neutral-100 px-7 gap-7">
                <div className="bg-white py-3 px-6 rounded-[1.25rem]">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 justify-center items-center">
                        {
                            navs.map((button, index) => (
                                <div key={index} className="w-full">
                                    {selectedMode === button.mode ? (
                                        <ButtonGradient
                                            icon={button.iconActive}
                                            heightClass="h-10"
                                            textStyle="text-[0.813rem] font-medium"
                                            hasBorder={false}
                                            bgActive="bg-[#F6F7F8]"
                                            colorGradient="bg-orange-gradient hover:bg-orange-gradient"
                                            onClick={() => setSelectedMode(button.mode)}
                                        >
                                            {_(button.label)}
                                        </ButtonGradient>
                                    ) : (
                                        <ButtonBase
                                            iconColor="#9CA3AF"
                                            icon={button.iconUnactive}
                                            className="bg-white text-[#9CA3AF] py-3 px-6 w-full text-[0.813rem] font-medium rounded-full hover:bg-[#F6F7F8]"
                                            onClick={() => setSelectedMode(button.mode)}
                                        >
                                            {_(button.label)}
                                        </ButtonBase>
                                    )}
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className="flex-1 overflow-auto">
                    <div className={selectedMode !== "Subscription" ? "bg-white p-7 rounded-[1.25rem]" : ""}>
                        {layouts[selectedMode]}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Settings;