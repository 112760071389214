import APIService from "../APIService";
import { DASHBOARD_TEMP_TEAMS_DATA } from "../../constants/DatasConstants";
import UserService from "../User/UserService";

let listTeams = null;

export const getTeams = () => {
    return new Promise((resolve, reject) => {
        if (listTeams === null) {
            // APIService.apiCall("products", "GET")
            //     .then(result => {
            //         console.log(result);
            //         resolve(result.data);
            //     })
            //     .catch(error => {
            //         reject(error);
            //     })

            //data template
            listTeams = DASHBOARD_TEMP_TEAMS_DATA
            resolve(listTeams)
        } else {
            resolve(listTeams);
        }
    });
}

export const getTeamMemberById = (id) => {
    return new Promise((resolve, reject) => {
        if ((listTeams === null)) {
            getTeams()
                .then(resTeams => {
                    const team = resTeams.find((item) => item.id.toString() === id.toString());
                    if(team){
                        resolve(team)
                    }else{
                        reject(new Error("Lead not found"));
                    }

                })
                .catch(err => reject(err))
        } else {
            const team = listTeams.find((item) => item.id.toString() === id.toString());
            if(team){
                resolve(team)
            }else{
                reject(new Error("Lead not found"));
            }
        }
    });
}

export const createTeamMember = (team) => {
    return new Promise((resolve, reject) => {
        if (listTeams === null) {
            APIService.apiCall("products", "POST", team, true, {})
                .then((res) => {
                    console.log(res);
                    resolve(true);
                })
                .catch(error => {
                    reject(error);
                })
        } else {
            resolve(listTeams);
        }
    });
}

class TeamService {
    static teamMembers = null;

    static async fetchTeamMembers() {
        this.teamMembers = await UserService.getUsers();
    }

    static async getTeamMembers() {
        await this.fetchTeamMembers();
        return this.teamMembers;
    }

    static async getTeamMemberById(id) {
        await this.fetchTeamMembers();
        return this.teamMembers.find(member => member.id.toString() === id.toString());
    }

    static getTeams = getTeams;
    static createTeamMember = createTeamMember;
}

export default TeamService;

