import React, { useEffect, useState } from 'react';
import SelectorDate from "../../../components/Selectors/SelectorDate";
import { Link, useParams } from "react-router-dom";
import { getFullPath } from "../../../router/Router";
import { Icons } from "../../../assets/Svgs";
import { useTranslation } from "../../../services/i18n/i18nService";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import StatisGrid from "../Statistics/tops/StatisGrid";
import ViewsOverTime from "../Statistics/tops/ViewsOverTime";
import StatisTopWebsite from "../Statistics/tops/StatisTopWebsite";
import StatisTopPictures from "../Statistics/tops/StatisTopPictures";
import StatisTopMedias from "../Statistics/tops/StatisTopMedias";
import StatisTopFiles from "../Statistics/tops/StatisTopFiles";
import StatisTopVideos from "../Statistics/tops/StatisTopVideos";
import StatisTopContacts from "../Statistics/tops/StatisTopContacts";
import PopupSelectorCalendar from "../../../components/Selectors/PopupSelectorCalendar";
import {usePopup} from "../../../services/PopupService";
import GeneralPerformService from "../../../services/Statistics/StatisticsGeneralPerformService";

function TeamStatisticsView() {
    const [ loading, setLoading ] = useState(true);
    const [ data, setData ] = useState([]);
    const { t: _ } = useTranslation();
    const { id } = useParams();
    const { addPopup } = usePopup();

    // useEffect(() => {
    //     setLoading(true);
    //     TeamsService.getTeamMemberById(parseInt(id))
    //         .then(res => {
    //             console.log(res)
    //             setData(res);
    //         })
    //         .catch(err => {
    //             console.error(err);
    //         })
    //         .finally(() => {
    //             setLoading(false);
    //         })
    // }, [id]);

    // replace by correct api
    useEffect(() => {
        setLoading(true);
        GeneralPerformService.getGeneralPerform()
            .then(res => {
                setData(res);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, [data]);

    return (
    <div className="p-7 flex flex-col gap-7">
        <header className="bg-neutral-100 flex justify-between items-center border-gray-200">
            <div className="text-xl gap-3 flex items-center">
                <Link to={ getFullPath(["ROOT", "TEAM"]) } className="flex justify-center">
                    <p className="hover:underline hover:select-all">{_("Team")}</p>
                </Link>
                <Icons.SvgArrowRightOutline color="#000000" />
                <Link to={ getFullPath(["ROOT", "TEAM", "VIEW_TEAM_MEMBER"]).replace(":id", id) } className="flex justify-center">
                    <p className="hover:underline hover:select-all whitespace-nowrap">{_("View this member")}</p>
                </Link>
                <Icons.SvgArrowRightOutline color="#000000" />
                {_("Statistics")}
            </div>

            <div className="flex items-center gap-7">
                <SelectorDate isFlexible={true} onClick={() => addPopup(<PopupSelectorCalendar />)}/>

                <Link to={ getFullPath(["ROOT", "TEAM", "VIEW_TEAM_MEMBER"]).replace(":id", id) } className="flex justify-center gap-[0.438rem]">
                    <ButtonBase className="bg-white shadow-sm text-sm text-black px-5 h-10 rounded-full" iconColor="black" icon={ <Icons.SvgArrowReturnOutline className="h-3" /> } >
                        {_("Back")}
                    </ButtonBase>
                </Link>
            </div>
        </header>

        <div className="flex-1 overflow-y-auto bg-neutral-100 gap-7 flex-col md:flex-row">
            {
                data[0] && (
                    <div className="flex gap-7 flex-col ss:overflow-x-scroll">
                        <StatisGrid datas={data[0].chart} grids={data[0].grids} />

                        <div className="md:flex md:flex-row xs:grid gap-7 md:h-80">
                            <div className="xs:w-full bg-white p-7 rounded-[0.625rem] flex flex-col shadow-sm overflow-auto">
                                <ViewsOverTime
                                    row
                                    datas={data[0].viewsOverTime}
                                    value={data[0].value}
                                    increase={data[0].increase}
                                    title={_("Profile Views Over Time")}
                                    displayViewSelector={true}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-7 h-auto">
                            <StatisTopWebsite datas={data[0].websites.sort((i1, i2) => (i2.clicks - i1.clicks)).slice(0, 5)} />
                            <StatisTopPictures datas={data[0].pictures.sort((i1, i2) => (i2.views - i1.views)).slice(0, 5)} />
                            <StatisTopMedias datas={data[0].medias.sort((i1, i2) => (i2.clicks - i1.clicks)).slice(0, 5)} />
                            <StatisTopFiles datas={data[0].downloads.sort((i1, i2) => (i2.clicks - i1.clicks)).slice(0, 5)} />
                            <StatisTopVideos datas={data[0].videos.sort((i1, i2) => (i2.views - i1.views)).slice(0, 5)} />
                            <StatisTopContacts datas={data[0].contacts.sort((i1, i2) => (i2.clicks - i1.clicks)).slice(0, 5)} />
                        </div>
                    </div>
            )}
        </div>
    </div>
    );
}

export default TeamStatisticsView;