import React, { useEffect, useState } from 'react';
import SelectorDate from "../../../components/Selectors/SelectorDate";
import { Icons } from "../../../assets/Svgs";
import { useTranslation } from "../../../services/i18n/i18nService";
import TopPerformService from "../../../services/Statistics/StatisticsTopPerformService";
import { Link, useParams } from "react-router-dom";
import { getFullPath } from "../../../router/Router";
import StatisTopContacts from "./tops/StatisTopContacts";
import StatisTopVideos from "./tops/StatisTopVideos";
import StatisTopFiles from "./tops/StatisTopFiles";
import StatisTopMedias from "./tops/StatisTopMedias";
import StatisTopPictures from "./tops/StatisTopPictures";
import StatisTopWebsite from "./tops/StatisTopWebsite";
import ViewsOverTime from "./tops/ViewsOverTime";
import StatisGrid from "./tops/StatisGrid";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import {usePopup} from "../../../services/PopupService";
import PopupSelectorCalendar from "../../../components/Selectors/PopupSelectorCalendar";

function StatisticsPerformMemberView() {
    const [ topPerfomers, setTopPerfomers ] = useState([]);
    const { addPopup } = usePopup();
    const [ loading, setLoading ] = useState(true);
    const { t: _ } = useTranslation();
    const { id } = useParams();

    useEffect(() => {
        setLoading(true);
        TopPerformService.getPerfomById(id)
            .then(res => {
                setTopPerfomers(res);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, [id]);

    return <div className="p-7 flex flex-col gap-7">
        <header className="bg-neutral-100 flex justify-between items-center border-gray-200 ">
            {
                topPerfomers
                    ?  <div className="text-xl gap-3 flex items-center">
                        <Link to={ getFullPath(["ROOT", "STATISTICS"]) } className="flex justify-center">
                            <p className="hover:underline hover:select-all">{_("Statistics")}</p>
                        </Link>
                        <Icons.SvgArrowRightOutline color="#000000" />
                        <Link to={ getFullPath(["ROOT", "STATISTICS", "PERFORMER"]) } className="flex justify-center">
                            <p className="hover:underline hover:select-all">{_("Top Perfomers")}</p>
                        </Link>
                        <Icons.SvgArrowRightOutline color="#000000" />
                        { topPerfomers.full_name}
                    </div>
                    : null
            }

            <div className="flex items-center gap-7">
                <SelectorDate isFlexible={true} onClick={() => addPopup(<PopupSelectorCalendar />)}/>
                <Link to={ getFullPath(["ROOT", "STATISTICS", "PERFORMER"]) } className="flex justify-center gap-[0.438rem]">
                    <ButtonBase className="bg-white text-black text-sm py-3.5 px-6 rounded-full" iconColor="black" icon={ <Icons.SvgArrowReturnOutline /> } >
                        {_("Back")}
                    </ButtonBase>
                </Link>
            </div>
        </header>

        <div className="flex-1 min-h-0 overflow-auto">
            {
                topPerfomers.datas
                    ? <div className="flex gap-7 flex-col ss:overflow-x-scroll">
                        <StatisGrid datas={ topPerfomers.datas[0].chart } grids={ topPerfomers.datas[0].grids } />

                        <div className="md:flex md:flex-row xs:grid gap-7 md:h-[20rem]">
                            <div className="xs:w-full bg-white p-7 rounded-[0.625rem] flex flex-col shadow-sm overflow-auto">
                                <ViewsOverTime
                                    row
                                    datas={ topPerfomers.datas[0].chart }
                                    value={789}
                                    increase={110}
                                    title={_("Profile Views Over Time")}
                                    displayViewSelector={true}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-7 h-auto">
                            <StatisTopWebsite datas={ topPerfomers.datas[0].websites.sort((i1, i2) => (i2.clicks - i1.clicks)).slice(0, 5) } />
                            <StatisTopPictures datas={ topPerfomers.datas[0].pictures.sort((i1, i2) => (i2.views - i1.views)).slice(0, 5) } />
                            <StatisTopMedias datas={ topPerfomers.datas[0].medias.sort((i1, i2) => (i2.clicks - i1.clicks)).slice(0, 5) } />
                            <StatisTopFiles datas={ topPerfomers.datas[0].downloads.sort((i1, i2) => (i2.clicks - i1.clicks)).slice(0, 5) } />
                            <StatisTopVideos datas={ topPerfomers.datas[0].videos.sort((i1, i2) => (i2.views - i1.views)).slice(0, 5) } />
                            <StatisTopContacts datas={ topPerfomers.datas[0].contacts.sort((i1, i2) => (i2.clicks - i1.clicks)).slice(0, 5) } />
                        </div>
                    </div>
                    : null
            }
        </div>
    </div>;
}

export default StatisticsPerformMemberView;