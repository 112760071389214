import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { getFullPath } from "../../../router/Router";
import { Icons } from "../../../assets/Svgs";
import CustomersService from "../../../services/Customer/CustomersService";
import { useTranslation } from "../../../services/i18n/i18nService";
import ButtonBack from "../../../components/Buttons/ButtonBack";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import InputField from "../../../components/Layouts/InputField";
import FieldCopy from "../../../components/Layouts/FieldCopy";
import InputTextarea from "../../../components/Layouts/InputTextarea";
import { format } from "date-fns";

function AccountEdit() {
    const { t: _ } = useTranslation();
    const { id, acc, custom } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [values, setValues] = useState({
        account_name: "",
        created_at: "",
        business_mail: '',
        password: '',
        role: '',
        note: '',
    });

    const cols = [
        [
            { id: 1, name: "account_name", label: "Name", isTextarea: false, isModifiable: true },
            { id: 3, name: "business_mail", label: "Business Mail", isTextarea: false, isModifiable: true },
            { id: 5, name: "role", label: "Role", isTextarea: false, isModifiable: true }
        ],
        [
            { id: 6, name: "created_at", label: "Created Date", isTextarea: false, isModifiable: false },
            { id: 7, name: "password", label: "Password", isTextarea: false, isModifiable: false },
            { id: 8, name: "note", label: "Note", isTextarea: true, isModifiable: true }
        ]
    ];

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        setLoading(true);
        CustomersService.getAccountById(id, acc)
            .then(resAcc => {
                setValues({...values, ...resAcc});
            })
            .catch(err => {
                console.error(err);
                navigate('../', { replace: true });
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id, acc, navigate]);

    const renderInputs = (inputs) => {
        return inputs.map(input => (
            input.isModifiable
                ? input.isTextarea
                ? <InputTextarea
                    key={input.id}
                    rows={3}
                    name={input.name}
                    value={values[input.name]}
                    onChange={onChange}
                    label={input.label}
                    required={false}
                    gapClass="gap-3.5"
                    widthInputClass="w-4/6"
                />
                : <InputField
                    key={input.id}
                    name={input.name}
                    value={values[input.name]}
                    onChange={onChange}
                    label={input.label}
                    widthInputClass="w-4/6"
                />
                : <FieldCopy
                    key={input.id}
                    label={input.label}
                    canCopy={false}
                >
                    {input.name === "created_at" ? (values.created_at ? format(new Date(values.created_at), 'dd/MM/yyyy') : "-") : values[input.name]}
                </FieldCopy>
        ));
    };

    return (
        <div className="flex flex-col h-screen p-7 gap-7">
            <header className="bg-neutral-100 z-10">
                <div className="flex justify-between">
                    <Link to={getFullPath(["ROOT", "CUSTOMERS"])} className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("Customers")}</p>
                    </Link>

                    <Icons.SvgArrowRightOutline color="#000000" />

                    <Link to={
                        getFullPath(["ROOT", "CUSTOMERS", "VIEW_CUSTOMER", "ACCOUNT_CUSTOMER", "VIEW_ACCOUNT"])
                            .replace(":id", id)
                            .replace(":acc", acc)
                            .replace(":custom", custom)
                    } className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("View")}</p>
                    </Link>

                    <Icons.SvgArrowRightOutline color="#000000" />

                    <Link to={
                        getFullPath(["ROOT", "CUSTOMERS", "VIEW_CUSTOMER", "ACCOUNT_CUSTOMER"])
                            .replace(":id", id)
                            .replace(":custom", custom)
                    } className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("Accounts")}</p>
                    </Link>

                    <Icons.SvgArrowRightOutline color="#000000" />

                    <Link to={
                        getFullPath(["ROOT", "CUSTOMERS", "VIEW_CUSTOMER", "ACCOUNT_CUSTOMER", "VIEW_ACCOUNT"])
                            .replace(":id", id)
                            .replace(":acc", acc)
                            .replace(":custom", custom)
                    } className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("View")}</p>
                    </Link>

                    <Icons.SvgArrowRightOutline color="#000000" />

                    {_("Edit")}
                </div>

                <div className="flex items-center gap-7">
                    <Link to={getFullPath(["ROOT", "CUSTOMERS", "VIEW_CUSTOMER", "ACCOUNT_CUSTOMER", "VIEW_ACCOUNT"]).replace(":id", id).replace(":acc", acc)} className="flex text-sm justify-center gap-[0.438rem]">
                        <ButtonBack isFlexible={false} />
                    </Link>
                    <ButtonBase className="bg-black text-white text-sm py-3.5 px-6 rounded-full" iconColor="white" icon={<Icons.SvgUserAddOutline className="h-3" />} >
                        {_("Save the modifications")}
                    </ButtonBase>
                </div>
            </header>

            <div className="flex-1 min-h-0 overflow-auto">
                <div className="w-full bg-white grid grid-cols-2 gap-7 rounded-[1.25rem]">
                    {
                        cols.map((col, index) => (
                            <div className="flex flex-col gap-7" key={index}>
                                {renderInputs(col)}
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default AccountEdit;