import React, { useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import { getFullPath } from "../../../router/Router";
import { Icons } from "../../../assets/Svgs";
import { useTranslation } from "../../../services/i18n/i18nService";
import CardsService from "../../../services/Card/CardsService";
import ButtonBack from "../../../components/Buttons/ButtonBack";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import InputSearch from "../../../components/Layouts/InputSearch";
import Table, { SelectionType } from "../../../components/Layouts/Table";
import FileService from '../../../services/FileService';
import CSVService from '../../../services/CSVService';
import ButtonGradient from "../../../components/Buttons/ButtonGradient";

function CardCreate() {
    const { t: _ } = useTranslation();
    const [listCards, setListCards] = useState([]);
    const fileInputRef = useRef(null);

    const [searchValue, setSearchValue] = useState("");

    const searchLambda = (o) => {
        const item = listCards[o.id];
        return (item.pin.indexOf(searchValue) !== -1)
            || (item.ref.indexOf(searchValue) !== -1)
            || (item.path.indexOf(searchValue) !== -1)
    }

    const updateCards = (cards) => {
        setListCards(cards);
        setSearchValue("");
    }

    const onSearch = (v) => {
        setSearchValue(v);
    }

    const handleImportedFiles = async (files) => {
        const file = files[0];
        const fileContent = await FileService.loadFile(file);
        try {
            const parsedData = CSVService.parseCSV(fileContent, ",", ["ref", "pin", "type", "path"]);
            updateCards([...listCards, ...parsedData]);
        }
        catch (error) {
            console.error(error);
        }
    }

    const handleImportCards = () => {
        console.log("Importing cards");
        const payLoad = listCards.map(card => {
            return {
                ref_number: card.ref,
                pin_code: card.pin,
                card_type: card.type,
                link: card.path,
            }
        });

        CardsService.bulkCreateCards(payLoad)
            .then(() => {
                console.log("Cards imported successfully");

            })
            .catch(err => {
                console.error(err);
            });
    }

    return (
        <div className="flex flex-col h-screen">
            <header className="bg-neutral-100 p-7 flex justify-between items-center">
                <div className="text-xl gap-3 flex items-center">
                    <Link to={getFullPath(["ROOT", "CARDS"])} className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("Cards")}</p>
                    </Link>

                    <Icons.SvgArrowRightOutline color="#000000" />

                    {_("Add New Cards")}
                </div>

                <div className="flex items-center gap-7">
                    <Link to={getFullPath(["ROOT", "CARDS"])} className="flex justify-center gap-[0.438rem] text-sm">
                        <ButtonBack isFlexible={false} />
                    </Link>

                    <ButtonBase
                        className="bg-black text-white text-sm py-3.5 px-6 rounded-full"
                        iconColor="white"
                        icon={<Icons.SvgUserAddOutline className="h-3" />}
                        onClick={handleImportCards}
                    >
                        {_("Import New Cards")} {listCards.length}
                    </ButtonBase>
                </div>
            </header>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7 px-7">
                <ButtonBase
                    className="text-white bg-black border border-[#D1D5DB] w-full text-sm py-3.5 px-6 rounded-full"
                    onClick={() => { fileInputRef.current.click() }}
                >
                    <Icons.SvgBrushOutline color="#FFFFFF" className="h-3" />
                    {_("Import from CSV")}
                </ButtonBase>

                <input
                    type="file"
                    accept=".csv"
                    className="hidden"
                    ref={fileInputRef}
                    onChange={(e) => {
                        const files = e.target.files;
                        handleImportedFiles(files).finally(() => {
                            e.target.value = null;
                        });
                    }}
                />

                <InputSearch
                    onSearch={() => { }}
                    setList={() => { }}
                    value={searchValue}
                    setValue={onSearch}
                    icon={<Icons.SvgFilterSearchOutline color="#D1D5DB" className="absolute top-1/2 left-3 -translate-y-1/2 h-3" />}
                    isFlexible={false}
                    placeholder={_("Search reference, material or path")}
                />

                <ButtonBase className="text-[#FF0000] bg-white border border-[#D1D5DB] w-full text-sm py-3.5 px-6 rounded-full">
                    <Icons.SvgTrashOutline color="#FF0000" className="h-3" />
                    {_("Remove")}
                </ButtonBase>
            </div>

            <div className="flex-1 overflow-auto bg-neutral-100 gap-7 px-7">
                <Table
                    selectionType={SelectionType.MULTIPLE}
                    filterLambda={searchLambda}
                    onSelectionChange={(selectedIds) => { console.log(selectedIds); }}
                    columns={[
                        {
                            label: _("Reference"),
                            sortable: false,
                        },
                        {
                            label: _("PIN Code"),
                            sortable: false,
                        },
                        {
                            label: _("Type"),
                            sortable: false,
                        },
                        {
                            label: _("Path"),
                            sortable: false,
                        },
                    ]}
                    rows={listCards.map((card, index) =>
                    ({
                        id: index,
                        data: [
                            {
                                value: <div className="flex items-center justify-center">
                                    {card.ref}
                                </div>,
                            },
                            {
                                value: <div className="flex items-center justify-center">
                                    {card.pin}
                                </div>,
                            },
                            {
                                value: <div className="flex items-center justify-center">
                                    {card.type}
                                </div>,
                            },
                            {
                                value: <div className="flex items-center justify-center">
                                    {card.path}
                                </div>
                            },
                        ]
                    })
                    )}
                />
            </div>
        </div>
    );
}

export default CardCreate;