import React, {useEffect, useState} from 'react';
import {useTranslation} from "../../../services/i18n/i18nService";
import AdminGeneralPerformService from "../../../services/StatisticsAdmin/AdminStatisticsGeneralPerformService";
import ViewsOverTime from "../Statistics/tops/ViewsOverTime";
import StatisAdminCurrentWrapper from "./StatisAdminCurrentWrapper";
import StatisAdminTopViewsCustomers from "./StatisAdminTopViewsCustomers";
import StatisGrid from "../Statistics/tops/StatisGrid";
import SelectorDate from "../../../components/Selectors/SelectorDate";
import StatisAdminTopPaymentCustomers from "./StatisAdminTopPaymentCustomers";
import PopupSelectorCalendar from "../../../components/Selectors/PopupSelectorCalendar";
import {usePopup} from "../../../services/PopupService";

function StatisticsAdmin() {
    const { t: _ } = useTranslation();
    const { addPopup } = usePopup();
    const [ generalPerform, setGeneralPerform ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        setLoading(true);
        AdminGeneralPerformService.getGeneralPerform()
            .then(res => {
                setGeneralPerform(res);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, [generalPerform]);

    return (
        <div className="flex flex-col h-screen">
            <header className="bg-neutral-100 border-gray-200 z-10 p-7 flex justify-between items-center">
                <div className="text-[1.5rem] leading-6 font-medium">{_("Statistics")}</div>
                <div className="flex items-center gap-7">
                    <SelectorDate isFlexible={false} onClick={() => addPopup(<PopupSelectorCalendar />)} />
                </div>
            </header>

            <div className="flex-1 overflow-y-auto p-7">
                {generalPerform[0] && (
                    <div className="flex flex-col gap-7">
                        <StatisGrid datas={generalPerform[0].chart} grids={generalPerform[0].grids} />

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-7 h-auto">
                            <div className="bg-white p-7 rounded-[0.625rem] flex flex-col shadow-sm">
                                <ViewsOverTime
                                    row
                                    datas={generalPerform[0].viewsOverTime}
                                    value={generalPerform[0].value}
                                    increase={generalPerform[0].increase}
                                    title={_("Profile Views Over Time")}
                                    displayViewSelector={true}
                                />
                            </div>
                            <div className="bg-white p-7 rounded-[0.625rem] flex flex-col shadow-sm">
                                <StatisAdminTopViewsCustomers datas={generalPerform[0].viewsCustomer.sort((i1, i2) => (i2.views - i1.views)).slice(0, 5)} />
                            </div>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-7 h-auto">
                            <div className="bg-white p-7 rounded-[0.625rem] flex flex-col shadow-sm">
                                <ViewsOverTime row datas={generalPerform[0].cardsOverTime} value={789} increase={-110}>
                                    <div className="text-sm">{_("Activated Cards Over Time")}</div>
                                </ViewsOverTime>
                            </div>
                            <div className="bg-white p-7 rounded-[0.625rem] flex flex-col shadow-sm">
                                <ViewsOverTime row datas={generalPerform[0].customersOverTime} value={789} increase={210}>
                                    <div className="text-sm">{_("Created Customers Over Time")}</div>
                                </ViewsOverTime>
                            </div>
                            <div className="bg-white p-7 rounded-[0.625rem] flex flex-col shadow-sm">
                                <ViewsOverTime row datas={generalPerform[0].cardsOverTime} value={"CHF 103.50"} increase={-110}>
                                    <div className="text-sm">{_("Performance Over Time")}</div>
                                </ViewsOverTime>
                            </div>
                            <div className="bg-white p-7 rounded-[0.625rem] flex flex-col shadow-sm">
                                <StatisAdminTopPaymentCustomers datas={generalPerform[0].paymentCustomer.sort((i1, i2) => (i2.total_earnings - i1.total_earnings)).slice(0, 5)} />
                            </div>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-7 h-auto">
                            <div className="bg-white p-7 rounded-[0.625rem] flex flex-col shadow-sm">
                                <StatisAdminCurrentWrapper value={generalPerform[0].currentMonth.total_earnings} increase={generalPerform[0].currentMonth.increase}>
                                    <div className="text-sm">{_("Current Month")}</div>
                                </StatisAdminCurrentWrapper>
                            </div>
                            <div className="bg-white p-7 rounded-[0.625rem] flex flex-col shadow-sm">
                                <StatisAdminCurrentWrapper value={generalPerform[0].revenueMonthly.total_earnings} increase={generalPerform[0].revenueMonthly.increase}>
                                    <div className="text-sm">{_("Monthly Recurrent Revenue")}</div>
                                </StatisAdminCurrentWrapper>
                            </div>
                            <div className="bg-white p-7 rounded-[0.625rem] flex flex-col shadow-sm">
                                <StatisAdminCurrentWrapper value={generalPerform[0].currentYear.total_earnings} increase={generalPerform[0].currentYear.increase}>
                                    <div className="text-sm">{_("Current Year")}</div>
                                </StatisAdminCurrentWrapper>
                            </div>
                            <div className="bg-white p-7 rounded-[0.625rem] flex flex-col shadow-sm">
                                <StatisAdminCurrentWrapper value={generalPerform[0].revenueAnnualy.total_earnings} increase={generalPerform[0].revenueAnnualy.increase}>
                                    <div className="text-sm">{_("Annually Recurrent Revenue")}</div>
                                </StatisAdminCurrentWrapper>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-7 h-auto">
                            <div className="bg-white p-7 rounded-[0.625rem] flex flex-col shadow-sm">
                                <ViewsOverTime row datas={generalPerform[0].revenueMonthlyOverTime} value={"CHF 13,103-"} increase={110}>
                                    <div className="text-sm">{_("Monthly Recurrent Revenue Over Time")}</div>
                                </ViewsOverTime>
                            </div>
                            <div className="bg-white p-7 rounded-[0.625rem] flex flex-col shadow-sm">
                                <ViewsOverTime row datas={generalPerform[0].revenueAnnualyOverTime} value={"CHF 13,103-"} increase={110}>
                                    <div className="text-sm">{_("Annually Recurrent Revenue Over Time")}</div>
                                </ViewsOverTime>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default StatisticsAdmin;