import React from 'react';
import { useTranslation } from "../../../services/i18n/i18nService";
import PopupBase from "../PopupBase";

function PopupResetPasswdSelectedTeam({ 
    teamMembers,
    onConfirm,
    onCancel,
 }) {
    
    const { t: _ } = useTranslation();

    return (
        <PopupBase
            title={
                teamMembers.length > 1
                    ? _("Are you sure you want to send a new password invitation to the selected team members?")
                    : _("Are you sure you want to send a new password invitation to this team member?")
            }
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        onClick: onCancel,
                    },
                    {
                        label: _("Confirm and Send a New Password Invitation"),
                        background: "bg-black",
                        color: "text-white",
                        onClick: onConfirm,
                    },
                ]
            }>
        </PopupBase>
    );
}

export default PopupResetPasswdSelectedTeam;