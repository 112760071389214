import React, {useEffect, useState} from 'react';
import {Icons} from "../../../assets/Svgs";
import {useTranslation} from "../../../services/i18n/i18nService";
import CardsService from "../../../services/Card/CardsService";
import {Link} from "react-router-dom";
import {getFullPath} from "../../../router/Router";
import InputSearch from "../../../components/Layouts/InputSearch";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import Table from "../../../components/Layouts/Table";
import ButtonViewDetails from "../../../components/Buttons/ButtonViewDetails";

const onSearch = (listToSearch, keyword) => {
    if (!keyword) return listToSearch;
    return listToSearch.filter(item => {
        return item.ref_number.toLowerCase().includes(keyword.toLowerCase());
    });
}

function Cards() {
    const { t: _ } = useTranslation();
    const [ listCards, setListCards ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ value, setValue ] = useState("");
    const [ connectionsOriginal, setConnectionsOriginal ] = useState([]);

    useEffect(() => {
        CardsService.getCards()
            .then(cards => {
                setListCards(cards);
                setConnectionsOriginal(cards);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    return (
        <div className="flex flex-col h-screen">
            <header className="bg-neutral-100 z-10">
                <div className="p-7 flex justify-between">
                    <div className="text-[1.5rem] items-center gap-3 flex font-medium">
                        {_("Cards")}
                        {!loading && <span className="text-[#4B5563] text-[1.25rem] leading-5 font-medium">({listCards.length})</span>}
                    </div>

                    <div className="flex items-center gap-7">
                        <InputSearch
                            onSearch={onSearch}
                            value={value}
                            setValue={setValue}
                            setList={setListCards}
                            listToSearchOriginal={connectionsOriginal}
                            icon={<Icons.SvgFilterSearchOutline color="#D1D5DB" className="absolute top-1/2 left-3 -translate-y-1/2 h-3" />}
                            isFlexible={false}
                            placeholder={"Search reference, material or company"}
                        />

                        <Link to={getFullPath(["ROOT", "CARDS", "CREATE_CARD"])} className="flex justify-center">
                            <ButtonBase className="bg-black text-white text-sm py-3.5 px-6 rounded-full" iconColor="white" icon={<Icons.SvgUserAddOutline className="h-3" />}>
                                {_("Import New Cards")}
                            </ButtonBase>
                        </Link>
                    </div>
                </div>
            </header>

            <div className="flex-1 overflow-auto bg-neutral-100 p-7">
                <Table
                    displayHeaders={true}
                    columns={[
                        {
                            label: _("Reference"),
                            sortable: false,
                        },
                        {
                            label: _("PIN Code"),
                            sortable: false,
                        },
                        {
                            label: _("Status"),
                            sortable: false,
                        },
                        {
                            label: _("Assigned to"),
                            sortable: false,
                        },
                        {
                            label: _("View"),
                            sortable: false,
                        },
                    ]}
                    rows={listCards.map(card => ({
                        data: [
                            {
                                value: <div className="flex items-center justify-center">
                                    {card.ref_number}
                                </div>,
                            },
                            {
                                value: <div className="flex items-center justify-center">
                                    {card.pin_code}
                                </div>,
                            },
                            {
                                value: <div className="flex items-center justify-center">
                                    <div className="w-36">
                                <span className={`capitalize w-full flex items-center justify-center py-1.5 px-2 rounded-[0.625rem] text-sm ${!!(card.assigned_to) ? "text-white bg-[#25D366]" : "text-white bg-[#FF0000]"}`}>
                                    {!!(card.assigned_to) ? _("Assigned") : _("Not assigned")}
                                </span>
                                    </div>
                                </div>,
                            },
                            {
                                value: <div className="flex items-center justify-center">
                                    {card.business_name ? <p>{card.business_name}</p> : <p>-</p>}
                                </div>
                            },
                            {
                                value: <Link to={getFullPath(["ROOT", "CARDS", "VIEW_CARD"]).replace(":id", card.id)} className="flex justify-center gap-[0.438rem]">
                                    <ButtonViewDetails />
                                </Link>,
                            },
                        ]
                    }))}
                />
            </div>
        </div>
    );
}

export default Cards;