import React, { useEffect, useState } from 'react';
import SelectorDate from "../../../components/Selectors/SelectorDate";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import { Icons } from "../../../assets/Svgs";
import { Link } from "react-router-dom";
import Table from "../../../components/Layouts/Table";
import { useTranslation } from "../../../services/i18n/i18nService";
import TopPerformService from "../../../services/Statistics/StatisticsTopPerformService";
import { getFullPath } from '../../../router/Router';
import TagTemplate from "../../../components/Tags/TagTemplate";
import ButtonViewDetails from "../../../components/Buttons/ButtonViewDetails";
import { usePopup } from "../../../services/PopupService";
import PopupTemplateFilterPersonView from "../Templates/Popups/PopupTemplateFilterPersonView";
import ButtonBack from "../../../components/Buttons/ButtonBack";
import PopupSelectorCalendar from "../../../components/Selectors/PopupSelectorCalendar";

function StatisticsTopPerformer() {
    const { t: _ } = useTranslation();
    const [ listCompletePerform, setListCompletePerform ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ selectedView, setSelectedView ] = useState("templates");
    const { addPopup } = usePopup();

    useEffect(() => {
        setLoading(true);
        TopPerformService.getTopPerforms()
            .then(datas => {
                setListCompletePerform(datas);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    return (
        <div className="p-7 flex flex-col gap-7">
            <header className="bg-neutral-100 flex justify-between items-center border-gray-200">

                <div className="flex text-xl whitespace-nowrap items-center gap-3">
                    <Link to={ getFullPath(["ROOT", "STATISTICS"]) } className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("Statistics")}</p>
                    </Link>
                    <Icons.SvgArrowRightOutline color="#000000" />
                    {_("Top Perfomers")}
                </div>

                <div className="flex items-center gap-7">
                    <Link to={getFullPath(["ROOT", "STATISTICS"])} className="flex justify-center gap-[0.438rem]">
                        <ButtonBack isFlexible={true} />
                    </Link>

                    <SelectorDate isFlexible={true} onClick={() => addPopup(<PopupSelectorCalendar />)}/>

                    <ButtonBase onClick={() => { addPopup(<PopupTemplateFilterPersonView title="Filter the statistics view by :" selectedView={ selectedView } onSelectedViewChange={ setSelectedView } />) }} className="bg-black text-white text-sm py-3.5 px-6 rounded-full" iconColor="white" icon={ <Icons.SvgStatisticFilterOutline /> } >
                        {_("Filter")}
                    </ButtonBase>
                </div>

            </header>

            <div className="flex-1 min-h-0 overflow-auto">
                <div className="flex">
                    <Table
                        tdpaddingClass="py-3.5 px-8"
                        displayHeaders={true}
                        columns={[
                            {
                                label: _("Team member"),
                                sortable: true,
                                sortOn: value => value.props.sort_on,
                            },
                            {
                                label: _("Views"),
                                sortable: true,
                                sortOn: value => value.props.sort_on,
                            },
                            {
                                label: _("Contacts"),
                                sortable: true,
                                sortOn: value => value.props.sort_on,
                            },
                            {
                                label: _("Leads"),
                                sortable: true,
                                sortOn: value => value.props.sort_on,
                            },
                            {
                                label: _("Template"),
                                sortable: true,
                                sortOn: value => value.props.sort_on,
                            },
                            {
                                label: _("View"),
                                sortable: false,
                            },
                        ]}
                        rows={listCompletePerform.map( lead =>
                            ({
                                data: [
                                    {
                                        value: <div sort_on={ lead.full_name } className="flex items-center gap-[1.5rem]">
                                            <img src={ lead.avatar } className="rounded-full h-8 w-8"  alt="avatar" />
                                            <span>{ lead.full_name }</span>
                                        </div>,
                                    },
                                    {
                                        value: <div sort_on={ lead.views } className="flex items-center justify-center gap-[1.5rem]">
                                            <span className="flex gap-[0.438rem]">
                                                { lead.views }
                                                <Icons.SvgStatisticEyeFill />
                                            </span>
                                        </div>,
                                    },
                                    {
                                        value: <div sort_on={ lead.contacts } className="flex items-center justify-center gap-[1.5rem]">
                                            <span className="flex gap-[0.438rem]">
                                                { lead.contacts }
                                                <Icons.SvgProfileTwoUser />
                                            </span>
                                        </div>,
                                    },
                                    {
                                        value: <div sort_on={ lead.leads } className="flex items-center justify-center gap-[1.5rem]">
                                            <span className="flex gap-[0.438rem]">
                                                { lead.leads }
                                                <Icons.SvgStatisticUserCircleAdd />
                                            </span>
                                        </div>,
                                    },
                                    {
                                        value: <div sort_on={ lead.template } className="flex items-center justify-center gap-[1.5rem]">
                                            <span>{ TagTemplate(lead.template, "bg-[#EEEFF2]") }</span>
                                        </div>,
                                    },
                                    {
                                        value: <Link to={getFullPath(["ROOT", "STATISTICS", "PERFORMER", "VIEW_PERFORM"]).replace(":id", lead.id)} className="flex justify-center gap-[0.438rem]">
                                            <ButtonViewDetails />
                                        </Link>,
                                    },
                                ]
                            })
                        )}
                    />
                </div>
            </div>
        </div>
    );
}

export default StatisticsTopPerformer;