import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { getFullPath } from "../../../router/Router";
import { Icons } from "../../../assets/Svgs";
import { useTranslation } from "../../../services/i18n/i18nService";
import CardsService from "../../../services/Card/CardsService";
import ButtonBack from "../../../components/Buttons/ButtonBack";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import FieldCopy from "../../../components/Layouts/FieldCopy";
import InputSelected from "../../../components/Layouts/InputSelected";
import InputField from "../../../components/Layouts/InputField";

function CardEdit() {
    const { t: _ } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [card, setCard] = useState({});
    const [values, setValues] = useState({
        ref_number: "",
        pin: "",
        path: "",
        link: "",
        type: "",
        status: "",
        activation: "",
        business_name: "",
    });

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        setLoading(true);
        CardsService.getCardById(id)
            .then(resCard => {
                setCard(resCard);
                setValues({
                    ref_number: resCard.ref_number || "",
                    pin: resCard.pin || "",
                    path: resCard.path || "",
                    link: resCard.link || "",
                    type: resCard.type || "",
                    status: resCard.status || "",
                    activation: resCard.activation || "",
                    business_name: resCard.business_name || "",
                });
            })
            .catch(err => {
                console.error(err);
                navigate('../', { replace: true });
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id, navigate]);

    const renderInput = (name) => {
        const isModifiable = ['ref_number', 'pin', 'path', 'link', 'type', 'status'].includes(name);
        const isSelected = name === 'status';

        if (!isModifiable) {
            return (
                <FieldCopy key={name} label={_(name)} canCopy={false}>
                    {card[name]}
                </FieldCopy>
            );
        }

        if (isSelected) {
            return (
                <InputSelected
                    key={name}
                    name={name}
                    label={_(name)}
                    widthInputClass="w-4/6"
                    bgColorClass="bg-white border border-[#D1D5DB]"
                    options={[values[name]]}
                    onChange={onChange}
                />
            );
        }

        return (
            <InputField
                key={name}
                widthInputClass="w-4/6"
                name={name}
                label={_(name)}
                value={values[name]}
                onChange={onChange}
            />
        );
    };

    return (
        <div className="flex flex-col h-screen m-7 gap-7">
            <header className="flex justify-between bg-neutral-100 z-10">
                <div className="text-xl gap-3 flex items-center">
                    <Link to={getFullPath(["ROOT", "CARDS"])} className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("Cards")}</p>
                    </Link>

                    <Icons.SvgArrowRightOutline color="#000000" />

                    <Link to={getFullPath(["ROOT", "CARDS", "VIEW_CARD"]).replace(":id", id)} className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("View")}</p>
                    </Link>

                    <Icons.SvgArrowRightOutline color="#000000" />

                    {_("Edit")}
                </div>

                <div className="flex items-center gap-7">
                    <Link to={getFullPath(["ROOT", "CARDS", "VIEW_CARD"]).replace(":id", id)} className="flex text-sm justify-center">
                        <ButtonBack isFlexible={false} />
                    </Link>
                    <ButtonBase className="bg-black text-white text-sm py-3.5 px-6 rounded-full" iconColor="white" icon={<Icons.SvgCardSave className="h-3" />}>
                        {_("Save the modifications")}
                    </ButtonBase>
                </div>
            </header>

            <div className="flex-1 min-h-0 overflow-auto">
                <div className="flex gap-7 flex-col overflow-x-scroll">
                    <div className="md:flex md:flex-row xs:grid gap-7 p-7 rounded-[1.25rem] bg-white">
                        <div className="w-full bg-white grid grid-rows-4 grid-flow-col gap-7">
                            {Object.keys(values).map(renderInput)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardEdit;