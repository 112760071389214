import React, { useEffect, useState } from 'react';
import { Icons } from "../../../assets/Svgs";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getFullPath } from "../../../router/Router";
import CardsService from "../../../services/Card/CardsService";
import { useTranslation } from "../../../services/i18n/i18nService";
import ButtonBack from "../../../components/Buttons/ButtonBack";
import ButtonBase from "../../../components/Buttons/ButtonBase";
import FieldCopy from "../../../components/Layouts/FieldCopy";

function CardView() {
    const { t: _ } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [card, setCard] = useState({});

    const fieldMapping = {
        ref_number: { label: "Reference Number", canCopy: true },
        pin_code: { label: "PIN Code", canCopy: true },
        link: { label: "Path", canCopy: true, format: (data) => window.location.origin.replace("dashboard.", "app.") + "/p/" + data.link },
        card_type: { label: "Type", canCopy: false },
        assigned_to: { label: "Status", canCopy: false, format: (data) => data.assigned_to ? _("Assigned") : _("Not assigned") },
        is_active: { label: "Activation", canCopy: false, format: (data) => data.is_active ? _("Active") : _("Inactive") },
        business_name: { label: "Assigned to", canCopy: true }
    };

    const buttons = [
        {
            label: "Edit",
            className: "bg-white text-black cursor-pointer",
            iconColor: "#000000",
            icon: <Icons.SvgBrushOutline className="h-3" />,
            onClick: () => navigate('./edit', { replace: true }),
        },
        {
            label: card["assigned_to"] ? "Unassign" : "Assign to a Customer",
            className: "bg-white text-black",
            iconColor: "#000000",
            icon: card["assigned_to"] ? <Icons.SvgCardUnAssignOutline className="h-3" /> : <Icons.SvgCardAssignOutline className="h-3" />,
        },
        {
            label: "Remove",
            className: "bg-white text-[#FF0000]",
            iconColor: "#FF0000",
            icon: <Icons.SvgTrashOutline className="h-3" />,
            onClick: () => {
                CardsService.deleteCard(id)
                    .then(() => {
                        navigate(getFullPath(["ROOT", "CARDS"]));
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }
        }
    ];

    useEffect(() => {
        CardsService.getCardById(id)
            .then(resCard => {
                setCard(resCard);
            })
            .catch(err => {
                console.error(err);
                navigate('../', { replace: true });
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id, navigate]);

    return (
        <div className="flex flex-col h-screen m-7 gap-7">
            <header className="flex justify-between bg-neutral-100 z-10">
                <div className="text-xl gap-3 flex items-center">
                    <Link to={getFullPath(["ROOT", "CARDS"])} className="flex justify-center">
                        <p className="hover:underline hover:select-all">{_("Cards")}</p>
                    </Link>

                    <Icons.SvgArrowRightOutline color="#000000" />

                    {_("View")}
                </div>

                <div className="flex items-center gap-7">
                    <Link to={getFullPath(["ROOT", "CARDS"])} className="flex text-sm justify-center gap-[0.438rem]">
                        <ButtonBack isFlexible={false} />
                    </Link>
                </div>
            </header>

            <div className="flex-1 min-h-0 overflow-auto">
                <div className="flex flex-col gap-7 p-7 rounded-[1.25rem] bg-white">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-7 justify-center items-center">
                        {
                            buttons.map((button, index) => (
                                <div key={index} onClick={button.onClick} className="w-full">
                                    <ButtonBase iconColor={button.iconColor} icon={button.icon} className={`${button.className} py-3 px-6 border border-[#D1D5DB] w-full text-sm font-medium rounded-full`}>
                                        {_(button.label)}
                                    </ButtonBase>
                                </div>
                            ))
                        }
                    </div>

                    <div className="w-full bg-white grid grid-rows-4 grid-cols-2 gap-7">
                        {
                            Object.keys(fieldMapping).map((key) => {
                                const { label, canCopy, format } = fieldMapping[key];
                                const value = format ? format(card) : card[key];
                                return (
                                    <FieldCopy key={key} label={_(label)} canCopy={canCopy}>
                                        {value}
                                    </FieldCopy>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardView;