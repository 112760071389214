import React from 'react';
import {usePopup} from "../../../services/PopupService";
import {useTranslation} from "../../../services/i18n/i18nService";
import PopupBase from "../PopupBase";
import InputField from "../../Layouts/InputField";

function PopupEditRefCard({handleDelete, handleSaveChanges, inputs, onChange, values}) {
    const { removePopup } = usePopup();
    const { t: _ } = useTranslation();

    return (
        <PopupBase
            title={_("Fill in the fields with the card informations previously provided:")}
            footerButtons={
                [
                    {
                        label: _("Cancel"),
                        background: "bg-white",
                        color: "text-black",
                        onClick: removePopup,
                    },
                    {
                        label: _("Delete the card"),
                        background: "bg-white",
                        color: "text-[#EC0000]",
                        onClick: handleDelete,
                    },
                    {
                        label: _("Save the modifications"),
                        background: "bg-black",
                        color: "text-white",
                        onClick: handleSaveChanges,
                    }
                ]
            }>
            <form className="w-full">
                <div className="px-[10%] flex flex-col gap-7">
                    {
                        inputs.map((input) => (
                            <InputField
                                name={input.name}
                                hasUrl={ input.hasUrl }
                                canCopy={ input.canCopy }
                                widthInputClass="w-full bg-white"
                                key={ input.id }
                                { ...input }
                                value={ values[input.name] }
                                onChange={ onChange }
                            />
                        ))
                    }
                </div>
            </form>
        </PopupBase>
    );
}

export default PopupEditRefCard;