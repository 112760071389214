import { cloneElement, useEffect, useState } from 'react';
import { Icons } from "../../assets/Svgs";
import ButtonGradient from "../Buttons/ButtonGradient";
import { useTranslation } from "../../services/i18n/i18nService";
import { getFullPath } from '../../router/Router';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import ButtonTranslate from "../Buttons/ButtonTranslate";
import AuthService from '../../services/Auth/AuthService';
import UserService from '../../services/User/UserService';

const DASHBOARD_SIDEBAR_LINKS = [
    {
        label: 'Customers',
        path: ['ROOT', 'CUSTOMERS'],
        icon: <Icons.SvgSidebarCustomer />,
        requiresStaffAccess: true,
    },
    {
        label: 'Cards',
        path: ['ROOT', 'CARDS'],
        icon: <Icons.SvgSidebarCardOutline />,
        requiresStaffAccess: true,
    },
    {
        label: 'Leads',
        path: ['ROOT', 'LEADS'],
        icon: <Icons.SvgSidebarLeads />,
    },
    {
        label: 'Statistics',
        path: ['ROOT', 'STATISTICS'],
        icon: <Icons.SvgSidebarStatistics />,
    },
    // {
    //     label: 'StatisticsAdmin',
    //     path: ['ROOT', 'ADMINSTATISTICS'],
    //     icon: <Icons.SvgSidebarStatistics />,
    // },
    {
        label: 'Team',
        path: ['ROOT', 'TEAM'],
        icon: <Icons.SvgSidebarTeam />,
    },
    {
        label: 'Divisions',
        path: ['ROOT', 'DIVISIONS'],
        icon: <Icons.SvgSidebarDivision />,
    },
    {
        label: 'Templates',
        path: ['ROOT', 'TEMPLATES'],
        icon: <Icons.SvgSidebarTemplates />,
    },
    {
        label: 'Settings',
        path: ['ROOT', 'SETTINGS'],
        icon: <Icons.SvgSidebarSettings />,
    },
];

function Sidebar() {
    /* Hooks */
    const { t: _ } = useTranslation();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    /* States */
    const [ currentUser, setCurrentUser ] = useState(null);

    /* Utils */
    const isManager = currentUser?.membership?.is_manager;
    const isStaff = currentUser?.is_staff;

    /* Effects */
    useEffect(() => {
        UserService.getCurrentUser()
            .then(user => {
                setCurrentUser(user);
            })
            .catch(err => {
                console.error(err);
            })
    }, []);

    return (
        <div className="flex flex-col bg-white">
            <div onClick={() => {navigate(getFullPath(["ROOT"]))}} className="flex items-center cursor-pointer">
                <img src={Icons.LogoProfylead} alt="Company logo" />
            </div>

            <div className="flex flex-col gap-3.5 p-7 overflow-y-auto h-full">
                {
                    DASHBOARD_SIDEBAR_LINKS
                    .filter(link => (
                        (!link.requiresManagerAccess || isManager)
                        && (!link.requiresStaffAccess || isStaff)
                    ))
                    .map((item, index) => (
                        <Link key={index} to={getFullPath(item.path)}>
                            <div className={`flex flex-row items-center hover:no-underline gap-3 px-4 py-3.5 hover:bg-orange-gradient rounded-[0.625rem] font-medium text-[1rem] leading-4
                                ${ pathname.startsWith(getFullPath(item.path)) ? 'text-white hover:bg-orange-gradient bg-orange-gradient' : 'text-[#4B5563] bg-white' }`}
                            >
                                {
                                    cloneElement(
                                        item.icon,
                                        {
                                            color: pathname.startsWith(getFullPath(item.path))
                                                ? 'white'
                                                : '#4B5563'
                                        }
                                    )
                                }
                                { _(item.label) }
                            </div>
                        </Link>
                    ))
                }

                <div className={`flex flex-row items-center hover:no-underline gap-3 px-4 py-3 rounded-[0.625rem] text-base font-semibold gap-0 text-[#4B5563] bg-white`}>
                    <Icons.SvgSidebarProfylead />
                    { _("Profylead") }
                </div>
            </div>

            <div className="flex flex-col gap-0.5 p-5">
                <ButtonTranslate />
                <ButtonGradient
                    roundedClass="rounded-full"
                    hasBorder={true}
                    colorGradient={`hover:bg-orange-gradient bg-orange-gradient`}
                    onClick={() => {
                        AuthService.signOut().then(_ => {
                            console.log("logged out successfully.")
                            navigate(getFullPath(["ROOT", "LOGIN"]));
                        });
                    }}
                >
                    {_("Log out")}
                </ButtonGradient>
            </div>
        </div>
    );
}

export default Sidebar;